import React, { memo, useCallback, useMemo, useState } from "react"

import { useCart } from "@app/hooks/useCart"
import { Box, AspectRatio, Image, Text, Flex, Button } from "@chakra-ui/react"
import { useConfigContext } from "@app/providers/config"
import { useGlobalContext } from "@app/providers/global"

type Props = {
  product: any
}

export const GiftWithPurchaseProduct: React.FC<Props> = memo(({ product }) => {
  const {
    settings: { cart },
  } = useConfigContext()
  const { addToCart } = useCart()
  const { additionalAdd } = useGlobalContext()
  const { additionalFreeGift } = useGlobalContext()
  const [loading, setLoading] = useState(false)

  const gwpKey = cart.gwpAttribute
  const productImage = useMemo(() => product?.images?.[0], [product?.images])
  const defaultVariant = useMemo(() => product?.variants?.[0], [product?.variants])

  const addToCartHandler = useCallback(async () => {
    const variantId = defaultVariant?.id
    if (!variantId) return

    setLoading(true)

    const attributes = [
      {
        key: gwpKey,
        value: "true",
      },
    ]

    await addToCart(variantId, 1, attributes)

    setLoading(false)
  }, [addToCart, defaultVariant?.id, gwpKey])

  return (
    <Box role="group">
      <Box pos="relative">
        <AspectRatio ratio={{ base: 155 / 196, sm: 1 / 1 }} mb={{ base: "2" }}>
          <Image src={productImage?.originalSrc} alt={productImage?.altText || ""} loading="lazy" />
        </AspectRatio>
      </Box>

      <Text size="xs" color="grey.lake" textAlign="left">
        {product?.title}
      </Text>

      <Flex justifyContent="space-between" alignItems={{ base: "flex-start", sm: "flex-end" }} flexDir={{ base: "column", sm: "row" }}>
        <Text size="xs" color="grey.lake">
          {additionalFreeGift}
        </Text>

        <Button
          fontWeight={600}
          onClick={addToCartHandler}
          isLoading={loading}
          width={{ base: "full", sm: "max-content" }}
          mt={{ base: 2, md: undefined }}
          variant="unstyled"
          display="inline-flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="xs"
          backgroundColor="white"
          color="grey.mid"
          fontSize="xxs"
          textTransform="uppercase"
          py={"6px"}
          px={{ base: "5.5" }}
          border="none"
          letterSpacing={"0.055em"}
          cursor="pointer"
        >
          {additionalAdd}
        </Button>
      </Flex>
    </Box>
  )
})
