import { keyframes } from "@chakra-ui/system"

const fade = () =>
  keyframes({
    from: { opacity: 0.25 },
    to: { opacity: 0.5 },
  })

export const Skeleton = {
  baseStyle: {
    opacity: 0.25,
    borderColor: "grey.fog",
    bg: "grey.fog",
    animation: `0.75s linear infinite alternate ${fade()}`,
  },
}
