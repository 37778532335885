import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Box, BoxProps, Button, FormLabel, Input, InputGroup, InputProps, InputRightAddon, Text, useDisclosure } from "@chakra-ui/react"
import { validate } from "react-email-validator"

import { useDom } from "@app/hooks/useDom"
import { FormErrors } from "./FormErrors"

import type { DisplayableErrorProps } from "@root/types/global"
import { useGlobalContext } from "@app/providers/global"

export type Props = InputProps & {
  autoFocus?: boolean
  background?: string
  containerProps?: BoxProps
  description?: string
  errors?: Array<DisplayableErrorProps | string>
  inputMode?: string
  id?: string
  isDisabled?: boolean
  name: string
  placeholder?: string
  reference?: React.Ref<HTMLInputElement>
  rightAddon?: JSX.Element
  type?: string
  value?: string
  variant?: string
  errorAlignment?: BoxProps["justifyContent"]
  errorIcon?: boolean
  labelColor?: string
}

export const FormInput: React.FC<Props> = ({
  autoFocus: rawAutoFocus = false,
  background = "white",
  containerProps,
  description,
  errors = [],
  inputMode,
  id,
  isDisabled,
  onBlur,
  onFocus,
  name,
  placeholder = null,
  reference = null,
  rightAddon,
  type = "text",
  value,
  variant,
  errorAlignment = "center",
  errorIcon = true,
  labelColor = "grey.mid",
  ...props
}): JSX.Element => {
  const { additionalHide, additionalShow } = useGlobalContext()
  const { isOpen: isShown, onToggle } = useDisclosure()
  const { navigator } = useDom()

  const autoFocus = useMemo(() => (rawAutoFocus && !navigator.iOS ? true : false), [navigator.iOS, rawAutoFocus])

  const [focus, setFocus] = useState<boolean>(autoFocus)

  const isInvalid = useMemo(() => (type === "email" && value && !validate(value) ? true : false), [type, value])

  const handleBlur = useCallback(
    event => {
      setFocus(false)
      if (onBlur) onBlur(event)
    },
    [onBlur, setFocus]
  )

  const handleFocus = useCallback(
    event => {
      setFocus(true)
      if (onFocus) onFocus(event)
    },
    [onFocus, setFocus]
  )

  useEffect(() => {
    if (isShown && !value) setTimeout(() => onToggle(), 200)
  }, [isShown, onToggle, value])

  return (
    <Box {...containerProps}>
      <InputGroup
        alignItems="center"
        color="grey.inactive"
        pos="relative"
        transition="color 0.2s ease"
        _focusWithin={{ color: "grey.text" }}
      >
        <Input
          autoFocus={autoFocus}
          css={{
            "&::-webkit-search-results-decoration,&::-webkit-search-results-button,&::-webkit-search-cancel-button,&::-webkit-search-decoration":
              {
                display: "none",
              },
            "&::-ms-clear,&::-ms-reveal": {
              display: "none",
            },
          }}
          id={id || name}
          inputMode={inputMode}
          isDisabled={isDisabled}
          isInvalid={errors?.length > 0 || isInvalid}
          name={name}
          onBlur={handleBlur}
          onFocus={handleFocus}
          ref={reference ? reference : null}
          letterSpacing="wider"
          placeholder={placeholder && variant?.includes("minimal") ? placeholder : ""}
          type={isShown ? "text" : type}
          value={value}
          variant={variant}
          sx={{
            borderBottomColor: errors.length ? "brand.error" : "grey.mid",
          }}
          {...(type === "password" || rightAddon ? { pr: 18 } : {})}
          {...props}
        />
        {type === "password" ? (
          <InputRightAddon
            pos="absolute"
            insetY={0}
            opacity={value ? 1 : 0}
            right={variant?.includes("minimal") ? 0 : 4}
            transition="all 0.2s ease"
            visibility={value ? "visible" : "hidden"}
            zIndex={1}
          >
            <Button
              color="grey.mid"
              fontSize="xxs"
              fontWeight="normal"
              lineHeight={1}
              mt={-0.75}
              onClick={onToggle}
              size="text"
              tabIndex="-1"
              title={isShown ? additionalHide : additionalShow}
              aria-label={isShown ? additionalHide : additionalShow}
              variant="ghost"
            >
              {isShown ? additionalHide : additionalShow}
            </Button>
          </InputRightAddon>
        ) : (
          rightAddon && (
            <InputRightAddon pos="absolute" insetY={0} right={variant?.includes("minimal") ? 0 : variant?.includes("filled") ? 1 : 4}>
              {rightAddon}
            </InputRightAddon>
          )
        )}
        {placeholder && !variant?.includes("minimal") && (
          <FormLabel
            bg={(type == "date" ? true : inputMode !== "none" ? focus : false) || value ? background : "transparent"}
            htmlFor={id || name}
            size={(type == "date" ? true : (inputMode !== "none" ? focus : false) || value) ? "active" : "base"}
            transform={(type == "date" ? true : (inputMode !== "none" ? focus : false) || value) ? "translateY(-130%)" : "translateY(0)"}
            variant={isDisabled ? "disabled" : variant}
            zIndex={1}
            color={labelColor}
          >
            {placeholder}
          </FormLabel>
        )}
      </InputGroup>
      {description && (
        <Text mb={-0.75} mt={2} size="xs" textAlign="center">
          {description}
        </Text>
      )}
      <FormErrors errors={errors} errorIcon={errorIcon} justifyContent={errorAlignment} mt={2} />
    </Box>
  )
}
