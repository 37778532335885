import React, { memo } from "react"
import { PageProps } from "gatsby"
import { Helmet } from "react-helmet"

import { useConfigContext } from "@app/providers/config"
import { useMeta } from "@app/hooks/useMeta"

import type { Location } from "@root/types/global"

type Props = {
  breadcrumbs?: Array<any>
  data: PageProps<any>
  location: Location
}

export const Meta: React.FC<Props> = memo(({ breadcrumbs = [], data, location }) => {
  const {
    app,
    store,
    settings: { optimizelyID, routes },
  } = useConfigContext()
  const { getData, getLanguages, getSchemas, getTags } = useMeta()
  const url = location?.pathname
  const search = location?.search
  const languages = getLanguages(url)
  const metadata = getData({
    url,
    data,
    routes,
    breadcrumbs,
    template: data?.template?.metadata,
    language: languages?.find(({ primary }) => primary)?.language?.split("_")?.[0] || "",
  })
  const metatags = getTags({ ...metadata, lang: metadata?.metaLang })
  const schemas = getSchemas(metadata)

  return (
    <>
      <Helmet>
        <html lang={metadata?.metaLang} />
        <title>{metadata?.title}</title>
        {metatags.map((tag, index) => (
          <meta key={index} {...tag} />
        ))}
        <style>
          {`
            /**
             * @license
             * MyFonts Webfont Build ID 4771489, 2022-05-03T02:28:23-0400
             *
             * The fonts listed in this notice are subject to the End User License
             * Agreement(s) entered into by the website owner. All other parties are
             * explicitly restricted from using the Licensed Webfonts(s).
             *
             * You may obtain a valid license at the URLs below.
             *
             * Webfont: Biotif-Book by Degarism Studio
             * URL: https://www.myfonts.com/fonts/degarism-studio/biotif/book/
             *
             * Webfont: Biotif-BookItalic by Degarism Studio
             * URL: https://www.myfonts.com/fonts/degarism-studio/biotif/book-italic/
             *
             * Webfont: Biotif-Medium by Degarism Studio
             * URL: https://www.myfonts.com/fonts/degarism-studio/biotif/medium/
             *
             * Webfont: Biotif-MediumItalic by Degarism Studio
             * URL: https://www.myfonts.com/fonts/degarism-studio/biotif/medium-italic/
             *
             * Webfont: Biotif-SemiBold by Degarism Studio
             * URL: https://www.myfonts.com/fonts/degarism-studio/biotif/semi-bold/
             *
             * Webfont: Biotif-SemiBoldItalic by Degarism Studio
             * URL: https://www.myfonts.com/fonts/degarism-studio/biotif/semi-bold-italic/
             *
             * Webfont: Biotif-Bold by Degarism Studio
             * URL: https://www.myfonts.com/fonts/degarism-studio/biotif/bold/
             *
             * Webfont: Biotif-BoldItalic by Degarism Studio
             * URL: https://www.myfonts.com/fonts/degarism-studio/biotif/bold-italic/
             *
             * Webfont: Biotif-Black by Degarism Studio
             * URL: https://www.myfonts.com/fonts/degarism-studio/biotif/black/
             *
             * Webfont: Biotif-BlackItalic by Degarism Studio
             * URL: https://www.myfonts.com/fonts/degarism-studio/biotif/black-italic/
             *
             *
             * Webfonts copyright: Copyright © 2016 by Deni Anggara. All rights reserved.
             *
             * © 2022 MyFonts Inc
            */
            @import url("//hello.myfonts.net/count/48cea1");
          `}
        </style>
        <script type="text/javascript">
          {`
            window.optimizely = window.optimizely || [];
            window.optimizely.push({
              "type": "waitForOriginSync",
              "canonicalOrigins": ["${app.domain}","${store.shopDomain}"]
            });
          `}
        </script>
        <link href={metadata?.siteURL} rel="home" />
        <link href={metadata?.canonical} rel="canonical" />

        <script type="text/javascript">{`
          var ScarabQueue = ScarabQueue || [];
          (function(id) {
            if (document.getElementById(id)) return;
            var js = document.createElement('script'); js.id = id;
            js.src = '//cdn.scarabresearch.com/js/1E7E7AB0DFCBAFFD/scarab-v2.js';
            var fs = document.getElementsByTagName('script')[0];
            fs.parentNode.insertBefore(js, fs);
          })('scarab-js-api');
        `}</script>

        {search ? <meta name="robots" content="noindex, follow" /> : <meta name="robots" content="index, follow" />}

        {languages?.map(({ key, href, hrefLang, rel }, index) => (
          <link key={key?.toString() + index} href={href} hrefLang={hrefLang} rel={rel} />
        ))}

        <script src={`//cdn.optimizely.com/js/${optimizelyID}.js`} />
      </Helmet>

      {schemas.map((schema, index) => (
        <script type="application/ld+json" key={index}>
          {JSON.stringify(schema)}
        </script>
      ))}
    </>
  )
})
