import React, { memo, useMemo } from "react"
import { StackProps, Text, Stack, StackDirection, Flex, TextProps } from "@chakra-ui/react"

import { useAppContext } from "@app/providers/app"
import { useShopifyPrice } from "@app/hooks/useShopify"

import type { ProductProps, ProductVariantProps } from "@root/types/global"
import { graphql, useStaticQuery } from "gatsby"
import { useGlobalContext } from "@app/providers/global"

type Props = StackProps & {
  product?: ProductProps
  size?: string
  color?: string
  variant?: ProductVariantProps
  priceVaries?: boolean
  direction?: StackDirection
  quantity?: number
  isGwp?: boolean
  isBuyXGetY?: boolean
  textProps?: TextProps
  lineHeight?: string
}

export const ProductFormPrice: React.FC<Props> = memo(
  ({
    priceVaries = false,
    product,
    size = "xs",
    color,
    variant,
    quantity = 1,
    isGwp = false,
    isBuyXGetY = false,
    textProps,
    lineHeight,
    ...props
  }) => {
    const { additionalFreeGift } = useGlobalContext()
    const { product: locales } = useStaticQuery<GatsbyTypes.ProductFormPriceQuery>(graphql`
      query ProductFormPrice {
        product: sanityTemplateProduct {
          additionalPriceVaries
        }
      }
    `)

    const stripZeroDecimals = (priceString: string | undefined) => {
      if (!priceString) return priceString
      return priceString.replace(/\.00$/, "")
    }

    const { activeVariant } = useAppContext()

    const fallbackVariant = useMemo(() => product?.variants?.[0] || null, [product?.variants])

    const { formattedPrice, formattedCompareAuantityPrice, formattedCompareAtPrice, onSale } = useShopifyPrice(
      variant || activeVariant || fallbackVariant,
      false,
      quantity,
      false
    )

    const isZeroPricing = formattedPrice && formattedPrice === "$0.00"

    if (isZeroPricing && !isBuyXGetY) return null
    return (
      <>
        <Stack {...props}>
          {isGwp || isBuyXGetY ? (
            <>
              <Text as="s" color="grey.mid" size={size} lineHeight={lineHeight ? lineHeight : ""} {...textProps}>
                {stripZeroDecimals(formattedPrice)}
              </Text>
              <Text color="grey.darker" size={size} lineHeight={lineHeight ? lineHeight : ""} {...textProps}>
                {(isGwp && additionalFreeGift) || (isBuyXGetY && "Free")}
              </Text>
            </>
          ) : onSale ? (
            <Flex gap={1}>
              <Text as="s" color={"grey.darker"} size={size} display="flex" lineHeight={lineHeight ? lineHeight : ""} {...textProps}>
                {quantity != 1 ? stripZeroDecimals(formattedCompareAuantityPrice) : stripZeroDecimals(formattedCompareAtPrice)}
              </Text>
              <Text color={color || "grey.mid"} size={size} lineHeight={lineHeight ? lineHeight : ""} {...textProps}>
                {stripZeroDecimals(formattedPrice)}
              </Text>
            </Flex>
          ) : (
            <Flex>
              {priceVaries && <Text size={size} lineHeight={lineHeight ? lineHeight : ""}>{`${locales?.additionalPriceVaries} `}</Text>}
              <Text size={size} color={color} lineHeight={lineHeight ? lineHeight : ""} {...textProps}>
                {stripZeroDecimals(formattedPrice)}
              </Text>
            </Flex>
          )}
        </Stack>
      </>
    )
  }
)

export default ProductFormPrice
