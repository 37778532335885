import { useCallback, useEffect, useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useCartContext } from "@app/providers/cart"
import { useCore } from "./useCore"
import { useCart } from "./useCart"
import { useConfigContext } from "@app/providers/config"
import { CartLine } from "@shopify/hydrogen-react/storefront-api-types"

export const useGiftWithPurchase = (enabled = false) => {
  const {
    helpers: { decodeShopifyId },
  } = useCore()
  const { cart } = useCartContext()
  const {
    settings: {
      cart: { gwpAttribute, gwpAutoAdd },
    },
  } = useConfigContext()
  const {
    removeFromCart,
    addToCart,
    loading,
    freeShipping: { threshold },
  } = useCart()

  const { gwpSettings } = useStaticQuery<GatsbyTypes.StaticGiftWithPurchaseQuery>(graphql`
    query StaticGiftWithPurchase {
      gwpSettings: sanitySettingGwp {
        giftWithPurchaseEnabled
        giftWithPurchaseExclude
        giftWithPurchaseProducts {
          autoAdd
          product {
            title
            shopify {
              id
              handle
              raw
              deleted
              active
              published
            }
          }
        }
      }
    }
  `)

  const lineItems = useMemo(() => cart?.lines, [cart?.lines])
  const minimumSpend = useMemo(() => threshold || 0, [threshold])

  const isGwp = useCallback(
    (item: CartLine): boolean => {
      return !!item?.attributes?.some(ca => ca.key === gwpAttribute)
    },
    [gwpAttribute]
  )
  const existsInCart = useCallback(
    (variantId: string) => {
      const lineItem = lineItems?.find((item: CartLine) => decodeShopifyId(item.merchandise.id, "ProductVariant") === variantId)
      return !!lineItem && !isGwp(lineItem)
    },
    [lineItems, decodeShopifyId, isGwp]
  )

  const addGwp = useCallback(
    async (variantId: string, autoAdd = false) => {
      const attributes = [
        {
          key: gwpAttribute,
          value: "true",
        },
      ]
      if (autoAdd) {
        attributes.push({
          key: gwpAutoAdd,
          value: "true",
        })
      }
      await addToCart(variantId, 1, attributes)
    },
    [addToCart, gwpAttribute, gwpAutoAdd]
  )

  const removeGwp = useCallback(
    async (product: any) => {
      const id = product?.id
      const variantId = product?.merchandise?.id
      await removeFromCart(id, variantId)
    },
    [removeFromCart]
  )

  const totalCartCost = useMemo((): number => {
    const filteredLineItems = lineItems?.filter((item: CartLine) => {
      const isGwPItem = isGwp(item)
      const excluded = gwpSettings?.giftWithPurchaseExclude
        ? item?.merchandise?.product?.tags?.includes(gwpSettings?.giftWithPurchaseExclude)
        : false
      return !isGwPItem && !excluded
    })

    const appliedDiscounts = filteredLineItems?.map(item => item?.discountAllocations?.[0]?.discountedAmount?.amount).filter(Boolean) ?? 0
    const totalAppliedDiscount = appliedDiscounts
      ? appliedDiscounts.reduce((total, amount) => parseFloat(total) + parseFloat(amount), 0)
      : 0

    const subtotal =
      filteredLineItems?.reduce((acc: number, item: CartLine) => {
        return acc + Number(item?.merchandise?.priceV2?.amount) * item?.quantity
      }, 0) ?? 0

    return subtotal - totalAppliedDiscount
  }, [lineItems, isGwp, gwpSettings])

  /**
   * Create array of products from parsed sanity product Shopify raw data.
   * Product needs to have at least one available variant and not already be in the cart.
   */
  const giftWithPurchaseProducts = useMemo(() => {
    return gwpSettings?.giftWithPurchaseProducts
      ?.map(({ product, autoAdd }: any) =>
        product?.shopify?.raw ? { shopify: product.shopify, ...(JSON.parse(product.shopify.raw) || {}), autoAdd } : null
      )
      .filter(p => {
        if (p.shopify.deleted || !p.shopify.active || !p.shopify.published) return
        const availableVariant = p?.variants?.find((v: any) => v.availableForSale === true)
        if (!availableVariant) return

        return p && !existsInCart(availableVariant.id)
      }) as any[]
  }, [gwpSettings?.giftWithPurchaseProducts, existsInCart])

  const isAGwpInCart = useMemo(() => {
    if (!lineItems?.length) return false

    return lineItems?.some((item: any) => isGwp(item))
  }, [lineItems, isGwp])

  const giftWithPurchaseEnabled = useMemo(() => {
    const hasGwpPurchase = gwpSettings?.giftWithPurchaseEnabled && giftWithPurchaseProducts.length

    return !!(hasGwpPurchase && totalCartCost >= minimumSpend && !isAGwpInCart)
  }, [gwpSettings?.giftWithPurchaseEnabled, giftWithPurchaseProducts.length, minimumSpend, totalCartCost, isAGwpInCart])

  const notEligibleForGwp = useMemo(() => {
    return !gwpSettings?.giftWithPurchaseEnabled || totalCartCost < minimumSpend
  }, [gwpSettings?.giftWithPurchaseEnabled, minimumSpend, totalCartCost])

  const gwpPercentage = useMemo(() => Math.min((totalCartCost / minimumSpend) * 100, 100), [totalCartCost, minimumSpend])

  const autoAddHandler = useCallback(async () => {
    const gwpCartItem = lineItems?.find((item: any) => isGwp(item))

    if (giftWithPurchaseEnabled && !gwpCartItem) {
      const availableVariant = giftWithPurchaseProducts?.find(p => !!p.autoAdd)?.variants?.find((v: any) => v.availableForSale === true)
      if (availableVariant) {
        await addGwp(availableVariant.id, true)
      }
    }
  }, [lineItems, giftWithPurchaseEnabled, isGwp, giftWithPurchaseProducts, addGwp])

  // add autoAdd gwp products
  useEffect(() => {
    if (!loading && enabled) autoAddHandler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoAddHandler, giftWithPurchaseEnabled, loading])

  // auto remove GWP from the cart if the cart total dips below threshold
  useEffect(() => {
    if (!enabled) return
    const findGwp = lineItems?.find((item: any) => isGwp(item))
    if (!loading && findGwp && notEligibleForGwp) {
      removeGwp(findGwp)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineItems, notEligibleForGwp, removeGwp, isGwp, totalCartCost, loading])

  return {
    gwpSettings,
    giftWithPurchaseEnabled,
    giftWithPurchaseProducts,
    gwpKey: gwpAttribute,
    isAGwpInCart,
    gwpPercentage,
    addGwp,
  }
}
