export const Textarea = {
  baseStyle: {
    border: "1px solid",
    borderRadius: "lg",
    fontSize: "xs",
    p: 5,
    h: 40,
    resize: "none",
    _disabled: {
      pointerEvents: "none",
    },
    _focus: {
      boxShadow: "none",
      outline: "none",
    },
  },
  variants: {
    outline: {
      borderColor: "grey.lake",
      boxShadow: "none",
      bg: "transparent",
      color: "grey.darker",
      _hover: {
        borderColor: "grey.lake",
        boxShadow: "none",
      },
      _focus: {
        borderColor: "grey.lake",
        boxShadow: "none",
      },
      _disabled: {
        borderColor: "grey.fog",
        boxShadow: "none",
        bg: "grey.cloud",
        color: "grey.fog",
        opacity: 1,
      },
      _invalid: {
        borderColor: "grey.lake",
        borderWidth: 1,
        boxShadow: "none",
      },
    },
    inverted: {
      borderColor: "grey.cloud",
      boxShadow: "none",
      bg: "transparent",
      color: "white",
      _hover: {
        borderColor: "white",
        boxShadow: "none",
      },
      _focus: {
        borderColor: "white",
        boxShadow: "none",
      },
      _disabled: {
        borderColor: "grey.fog",
        boxShadow: "none",
        bg: "grey.mid",
        color: "grey.fog",
        opacity: 1,
      },
      _invalid: {
        borderColor: "grey.lake",
        borderWidth: 1,
        boxShadow: "none",
      },
    },
  },
  defaultProps: {
    size: "base",
    variant: "outline",
  },
}
