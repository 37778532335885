export const PRODUCT_CARD_FRAGMENT = `#graphql
  fragment ProductCardFragment on Product {
    id
    title
    vendor
    handle
    options {
      name
      values
    }
    productType
    priceRange {
      maxVariantPrice {
        amount
        currencyCode
      }
      minVariantPrice {
        amount
        currencyCode
      }
    }
    tags
    images(first: $firstImages){
      edges {
        node {
          ...ImageFragment
        }
      }
    }
    variants(first: $firstVariants) {
      edges {
        node {
          ...ProductVariantFragment
        }
      }
    }
  }
`

export const PRODUCT_VARIANT_FRAGMENT = `#graphql
  fragment ProductVariantFragment on ProductVariant {
    id
    availableForSale
    selectedOptions {
      name
      value
    }
    image {
      id
      url
      altText
      width
      height
    }
    priceV2 {
      amount
      currencyCode
    }
    compareAtPriceV2 {
      amount
      currencyCode
    }
    sku
    quantityAvailable
    title
    unitPrice {
      amount
      currencyCode
    }
    product {
      title
      handle
    }
  }
`
