import React, { memo, useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Text } from "@chakra-ui/react"

export const CartPayments: React.FC = memo(() => {
  const { payments } = useStaticQuery<GatsbyTypes.StaticPaymentsQuery>(graphql`
    query StaticPayments {
      payments: sanitySettingPayments {
        enabled {
          value
        }
        additionalCheckout
      }
    }
  `)

  const methods = useMemo(
    () =>
      payments?.enabled?.reduce(
        (all, method, index) =>
          !index ? method?.value : index === payments?.enabled.length - 1 ? `${all} or ${method?.value}` : `${all}, ${method?.value}`,
        ""
      ),
    [payments?.enabled]
  )

  return payments?.enabled?.length > 0 ? (
    <Text letterSpacing="generous" size="xxs" textAlign="center">
      {payments?.additionalCheckout?.replace("{methods}", methods)}
    </Text>
  ) : null
})
