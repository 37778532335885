import React, { memo, useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Text, VStack } from "@chakra-ui/react"
import { useSearch } from "@usereactify/search"

import { useCore } from "@app/hooks/useCore"
import { SearchFormResultsPopular } from "./SearchFormResultsPopular"
import { SearchFormRecommendations } from "@app/components/Search/Form/Related/SearchFormRecommendations"

type Props = {
  error?: boolean
  setValue?: (term: string) => void
}

export const SearchFormResultsListEmpty: React.FC<Props> = memo(({ error, setValue }) => {
  const { search } = useStaticQuery<GatsbyTypes.StaticSearchFormResultsListEmptyQuery>(graphql`
    query StaticSearchFormResultsListEmpty {
      search: sanityPageSearch {
        additionalEmpty
      }
    }
  `)
  const {
    helpers: { linebreak },
  } = useCore()
  const { searchTerm } = useSearch()

  const locale = useMemo(
    () =>
      error
        ? "Sorry, there was an error displaying products at this time."
        : search?.additionalEmpty?.replace("{terms}", `<span style="font-weight: 400;">${searchTerm || ""}</span>`),
    [error, search?.additionalEmpty, searchTerm]
  )

  return (
    <VStack flex={1} alignItems="stretch" justifyContent="space-between" mx={[0, -6]}>
      <Box>
        <Text
          fontWeight={600}
          letterSpacing="generous"
          size="xs"
          textAlign="center"
          dangerouslySetInnerHTML={{ __html: linebreak(locale) }}
        />
        {setValue && <SearchFormResultsPopular setValue={setValue} />}
      </Box>
      <VStack alignItems="center" justifyContent="center" bg="grey.cloud" w="full">
        <SearchFormRecommendations />
      </VStack>
    </VStack>
  )
})
