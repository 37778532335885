export const Switch = {
  baseStyle: {
    container: {},
    track: {
      bg: "white",
      border: "1px solid",
      borderColor: "grey.darker",
      h: 3,
      w: 6.5,
      radius: "md",
      _focus: {
        boxShadow: "none",
      },
      _checked: {
        bg: "white",
      },
    },
    thumb: {
      bg: "grey.darker",
      h: 3,
      w: 3,
      transition: "all 0.15s ease",
      _hover: {
        bg: "grey.coal",
      },
    },
  },
}
