export const Badge = {
  baseStyle: {
    border: "1px solid",
    borderColor: "white",
    borderRadius: "sm",
    fontSize: "xxxs",
    fontWeight: 400,
    letterSpacing: "generous",
    lineHeight: 1,
    textTransform: "uppercase",
    bg: "white",
    color: "grey.coal",
    px: 2.5,
    pt: 2,
    pb: 1.5,
  },
  variants: {
    sale: {
      color: "brand.sale",
    },
  },
  defaultProps: {
    size: "base",
    variant: "base",
  },
}
