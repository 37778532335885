export const Drawer = {
  baseStyle: {
    footer: {
      bg: "grey.cloud",
    },
    body: {
      bg: "grey.cloud",
    },
    dialog: {
      boxShadow: "none",
      maxW: "full",
    },
    overlay: {
      bg: "grey.overlay",
      backdropFilter: "blur(11px)",
      transition: "all 0.2s ease",
    },
  },
  sizes: {
    xl: {
      dialog: { maxW: { base: "full", sm: 810, lg: "50vw" } },
    },
    lg: {
      dialog: { maxW: { base: "full", md: "50vw" } },
    },
    md: {
      dialog: { maxW: 577 },
      body: { px: 7.5 },
    },
    navigation: {
      header: { paddingY: "0", paddingX: "4" },
      dialog: { maxW: "min(90%,376px)" },
      overlay: { bg: "grey.overlayDark" },
      body: { bg: "grey.white", paddingX: "0", paddingTop: "0", paddingBottom: "0" },
      footer: { bg: "grey.cloud", paddingY: "0", paddingX: "6" },
    },
  },
  defaultProps: {
    size: "md",
  },
}
