import React, { memo, useCallback, useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Drawer, DrawerBody, DrawerFooter, DrawerOverlay, DrawerContent, Flex, Heading, VStack } from "@chakra-ui/react"
import useScrollbarSize from "react-scrollbar-size"

import { useAppContext } from "@app/providers/app"
import { useCartContext } from "@app/providers/cart"
import { useRoutes } from "@app/hooks/useRoutes"
import { CartShipping } from "./CartShipping"
import { CartList } from "./List/CartList"
import { CartRelated } from "./CartRelated"
import { CartSummary } from "./CartSummary"
import { FormButton } from "@app/components/Form/FormButton"
import { Icon } from "@app/components/Icon"
import { Link } from "@app/components/Link"
import { GiftWithPurchase } from "@app/components/Cart/GiftWithPurchase/GiftWithPurchase"
import { useGlobalContext } from "@app/providers/global"

export const CartMini: React.FC = memo(() => {
  const { additionalClose } = useGlobalContext()
  const { cart } = useStaticQuery<GatsbyTypes.StaticCartMiniQuery>(graphql`
    query StaticCartMini {
      cart: sanityPageCart {
        title
        link: _rawLink(resolveReferences: { maxDepth: 2 })
        additionalEmpty
      }
    }
  `)
  const { count } = useCartContext()
  const { state, dispatch, isCartLoading } = useAppContext()
  const { width } = useScrollbarSize()
  const { urlResolver } = useRoutes()

  const handleClick = useCallback(() => {
    dispatch({
      type: "cart",
      payload: !state.activeCart,
    })
  }, [dispatch, state])

  const link = useMemo(() => urlResolver(cart?.link), [cart?.link, urlResolver])

  return (
    <Drawer
      blockScrollOnMount={width === 0}
      isOpen={state.activeCart}
      placement="right"
      size="xl"
      onClose={handleClick}
      returnFocusOnClose={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        {count > 0 ? (
          <>
            <DrawerBody display="flex" flexDir="column" justifyContent="space-between" bg="white" p={0}>
              <Box>
                <Flex alignItems="center" justifyContent="flex-end" px={[4.25, 10.5]} pt={[4.25, 10.5]}>
                  <FormButton onClick={handleClick} p={2} title={additionalClose} aria-label={additionalClose} variant="ghost">
                    <Icon name="close" width={5} />
                  </FormButton>
                </Flex>
                <Box px={[6.75, 12.5]} pb={3.5} pt={4}>
                  <Heading as="p" color="grey.mid" fontSize="xl" lineHeight={1.75} size="h4" textAlign="center">
                    {cart?.title}
                  </Heading>
                  <CartShipping />
                </Box>
                <GiftWithPurchase />
                <Box px={[6.75, 12.5]} pb={3.5} pt={4}>
                  <CartList />
                </Box>
              </Box>
            </DrawerBody>
            <DrawerFooter bg="white" px={[6.75, 12.5]} pt={[7.5, 4]} pb={6.25}>
              <CartSummary disableCart={isCartLoading} />
            </DrawerFooter>
          </>
        ) : (
          <>
            <DrawerBody bg="white" display="flex" flexDirection="column" alignItems="stretch" justifyContent="space-between" p={0}>
              <Flex alignItems="center" justifyContent="flex-end" px={[4.25, 12]} pt={[4.25, 12, 25]}>
                <FormButton onClick={handleClick} p={2} title={additionalClose} aria-label={additionalClose} variant="ghost">
                  <Icon name="close" width={5} />
                </FormButton>
              </Flex>
              <VStack
                alignItems="center"
                justifyContent="center"
                flex={1}
                spacing={7.5}
                px={[6.25, 12.5]}
                pt={[10, 5]}
                pb={[16, 25]}
                textAlign="center"
              >
                <Heading as="p" color="grey.mid" fontSize="xl" lineHeight={1.75} size="h4" maxW={320}>
                  {cart?.additionalEmpty}
                </Heading>
                {link?.title && link?.url && (
                  <FormButton
                    as={Link}
                    onClick={handleClick}
                    size="md"
                    title={link?.title}
                    aria-label={link?.title}
                    to={link?.url}
                    variant="primary"
                    w={["full", 320]}
                  >
                    {link?.title}
                  </FormButton>
                )}
              </VStack>
              <VStack alignItems="center" justifyContent="center" bg="grey.cloud" px={[6.25, 12.5]}>
                <CartRelated />
              </VStack>
            </DrawerBody>
          </>
        )}
      </DrawerContent>
    </Drawer>
  )
})
