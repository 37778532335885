import React from "react"
import { ChakraProvider, extendTheme } from "@chakra-ui/react"

import themeConfig from "@app/theme/theme"

export const ThemeProvider: React.FC = ({ children }) => {
  const theme = extendTheme(themeConfig)

  return <ChakraProvider theme={theme}>{children}</ChakraProvider>
}
