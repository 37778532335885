import React, { memo } from "react"
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/react"
import { SubscribeForm } from "./SubscribeForm"
import { SubscribeClose } from "./SubscribeClose"
import { useNewsletter } from "@app/hooks/useNewsletter"
import type { Location } from "@root/types/global"

type Props = {
  location: Location
}

export const SubscribePopup: React.FC<Props> = memo(({ location }) => {
  const { activeSubscribe: open, handleClose } = useNewsletter(location)

  return (
    <>
      <Modal returnFocusOnClose={false} isOpen={open} onClose={handleClose}>
        <ModalOverlay background="#00000050" backdropFilter="none" />
        <ModalContent
          background="linear-gradient(164.05deg, rgba(255, 255, 255, 0.4) 4.74%, rgba(255, 255, 255, 0.1) 87.7%)"
          h={{ base: "auto" }}
          w={{ base: "calc(100% - 50px)", md: "845px" }}
          my="8rem"
          boxShadow="0px 4px 24px -1px rgba(0, 0, 0, 0.2)"
          backgroundColor="rgba(0,0,0,0.3)"
          backdropFilter="blur(20px)"
          dropShadow="#00000033"
          overflowY="auto"
        >
          <SubscribeClose setOpen={handleClose} />
          <ModalBody>
            <SubscribeForm location={location} setOpen={handleClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
})
