import React from "react"
import { Flex, Heading, Text } from "@chakra-ui/react"
import SubscribeIcon from "./SubscribeIcon"

type Props = {
  setOpen: (open: boolean) => void
}

const SubscribeThankYou = ({ setOpen }: Props) => {
  return (
    <Flex position="relative" justifyContent="center" alignItems="center" h="100%" flexDirection="column" padding="30px">
      <Heading
        as="h2"
        fontSize="lg"
        color="#fff"
        textTransform="uppercase"
        letterSpacing="0.3em"
        lineHeight="40px"
        pb={{ base: "40px", md: "40px" }}
        textAlign="center"
      >
        Thank you for subscribing
      </Heading>
      <SubscribeIcon />
      <Text
        color="#fff"
        textTransform="uppercase"
        fontSize="8px"
        letterSpacing="0.29em"
        position="absolute"
        bottom={{ base: "64px", md: "48px" }}
        onClick={() => setOpen(false)}
        _hover={{ cursor: "pointer" }}
      >
        Close this window
      </Text>
    </Flex>
  )
}

export default SubscribeThankYou
