import React, { memo, useCallback } from "react"
import { ReactifySearchProvider } from "@usereactify/search"
import { Drawer, DrawerContent, DrawerOverlay } from "@chakra-ui/react"
import useScrollbarSize from "react-scrollbar-size"

import { useAppContext } from "@app/providers/app"
import { useConfigContext } from "@app/providers/config"
import { useSearchRedirect } from "@app/hooks/useSearch"
import { useShop } from "@app/hooks/useShop"
import { SearchFormContent } from "./SearchFormContent"

export const SearchForm: React.FC = memo(() => {
  const { dispatch, state } = useAppContext()
  const {
    services: { reactify },
  } = useConfigContext()
  const { handleRedirect } = useSearchRedirect()
  const { width } = useScrollbarSize()
  const { shop } = useShop()

  const handleClick = useCallback(() => {
    dispatch({
      type: "search",
      payload: !state.activeSearch,
    })
  }, [dispatch, state])

  return (
    <Drawer
      blockScrollOnMount={width === 0}
      isOpen={state.activeSearch}
      onClose={handleClick}
      placement="right"
      size="lg"
      returnFocusOnClose={false}
    >
      <DrawerOverlay />
      {state.activeSearch && (
        <ReactifySearchProvider
          includeFields={reactify.includeFields}
          excludeFields={reactify.excludeFields}
          mode="instant-search"
          onRedirect={handleRedirect}
          shopifyPermanentDomain={shop?.myshopifyDomain ?? ""}
        >
          <DrawerContent bg="grey.mid">
            <SearchFormContent />
          </DrawerContent>
        </ReactifySearchProvider>
      )}
    </Drawer>
  )
})
