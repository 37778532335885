import { useStaticQuery, graphql } from "gatsby"
import { Product } from "shopify-storefront-api-typings"

export const useBackInStock = (product: Product) => {
  const { data } = useStaticQuery<GatsbyTypes.StaticBackInStockQuery>(graphql`
    query StaticBackInStock {
      data: sanitySettingBackInStock {
        title1
        title2
        content1
        subContent1
        content2
        button1
        colourLabel
      }
    }
  `)

  const title1 = data?.title1 || ""
  const content1 = data?.content1 ? data.content1.split("\n").filter(item => item) : []
  const subContent1 = data?.subContent1 ? data.subContent1.split("\n").filter(item => item) : []
  const button1 = data?.button1 || ""
  const title2 = data?.title2 || ""
  const productTitle = product?.title
  const content2 = data?.content2 ? data.content2.split("\n").map(item => item.replace("{item}", productTitle)) : []
  const colourLabel = data?.colourLabel || ""

  return {
    title1,
    content1,
    button1,
    subContent1,
    title2,
    content2,
    productTitle,
    colourLabel,
  }
}
