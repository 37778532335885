export const FormLabel = {
  baseStyle: {
    color: "grey.darker",
    fontSize: "xs",
    fontWeight: 400,
    letterSpacing: "generous",
    lineHeight: 1,
    mx: 4,
    mb: 0,
    p: 1,
    pointerEvents: "none",
    pos: "absolute",
    transition: "all 0.2s ease",
    transform: "translateY(0%)",
    zIndex: 1,
  },
  sizes: {
    active: {
      fontSize: "xxs",
      letterSpacing: "ample",
      transform: "translateY(-130%)",
    },
  },
  variants: {
    disabled: {
      color: "grey.fog",
    },
    inverted: {
      color: "white",
    },
    minimal: {
      mx: 0,
      px: 0,
      opacity: 0.5,
    },
    minimalInverted: {
      color: "white",
      letterSpacing: "wider",
      mx: 0,
      px: 0,
      textTransform: "uppercase",
    },
  },
}
