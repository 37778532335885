import React, { memo, useMemo } from "react"
import { Box, Progress, Text } from "@chakra-ui/react"

import { useCart } from "@app/hooks/useCart"
import { useGiftWithPurchase } from "@app/hooks/useGiftWithPurchase"

export const CartShipping: React.FC = memo(() => {
  const {
    freeShipping: { percentage: freeShippingPercentage, message: freeShippingMessage },
  } = useCart()
  const { gwpPercentage, gwpSettings } = useGiftWithPurchase()

  const content = useMemo(() => {
    return gwpSettings?.giftWithPurchaseEnabled
      ? {
          message: freeShippingMessage,
          percentage: gwpPercentage,
        }
      : {
          message: freeShippingMessage,
          percentage: freeShippingPercentage,
        }
  }, [gwpSettings?.giftWithPurchaseEnabled, freeShippingMessage, freeShippingPercentage, gwpPercentage])

  return (
    <Box mt={1.5} mb={7}>
      <>
        <Box mb={6} textAlign="center">
          <Text mb={1} size="xs" fontSize={["xxs", "xs"]} variant="caps">
            {content.message}
          </Text>
        </Box>
        <Progress value={content.percentage} />
      </>
    </Box>
  )
})
