import { FormErrors } from "@app/components/Form/FormErrors"
import { FormInput } from "@app/components/Form/FormInput"
import { FormSelect } from "@app/components/Form/FormSelect"
import { useBackInStock } from "@app/hooks/useBackInStock"
import { useMedia } from "@app/hooks/useMedia"
import { SwatchFull } from "@app/hooks/useSwatch"
import { useAppContext } from "@app/providers/app"
import { useConfigContext } from "@app/providers/config"
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
  Text,
  Button,
} from "@chakra-ui/react"
import { ProductCombinedProps, ProductVariantProps } from "@root/types/global"
import axios from "axios"
import React, { useRef, useState } from "react"

type Props = {
  isOpen: boolean
  onClose: () => void
  product: ProductCombinedProps | SwatchFull
  formattedProduct: SwatchFull
  variant?: ProductVariantProps
}

export const ProductFormBackInStock: React.FC<Props> = ({ isOpen, onClose, product, formattedProduct, variant }) => {
  const { activeVariant: contextVariant } = useAppContext()
  const {
    store,
    settings: {
      product: { colourPrefix },
    },
    services: { alertMe },
  } = useConfigContext()
  const activeVariant = variant || contextVariant

  const { isMedium } = useMedia()
  const initialRef = useRef()
  const { title1, title2, content1, subContent1, content2, button1, colourLabel } = useBackInStock(product)
  const { swatch } = formattedProduct
  const colourTag = swatch?.handle?.current || formattedProduct.tags.find(tag => tag.includes(colourPrefix))?.split(":")[1]

  const [email, setEmail] = useState("")
  const [sent, setSent] = useState(false)
  const [loading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const [formError, setFormError] = useState<string | null>(null)

  const isValidEmail = (email: string) => {
    return email?.length > 3 && /\S+@\S+\.\S+/.test(email)
  }

  const extractIdFromGid = (gid: string | null) => (gid ? gid.match(/[^/]+$/i)?.[0] : null)
  const variantId = extractIdFromGid(activeVariant?.storefrontId || activeVariant?.id || product.variants[0].id)
  const [activeSize, setActiveSize] = useState<number>()

  const onSubmit = async (e: any) => {
    e.preventDefault()

    if (isValidEmail(email)) {
      setIsLoading(true)
      const body = {
        store: store.shopNameUrl,
        email,
        product: extractIdFromGid(product?.product?.storefrontId) || product?.legacyId,
        url: `${product?.url}/${product.handle}`,
        variant: activeSize || variantId,
        ajax: true,
      }

      try {
        await axios.post(alertMe.serviceUrl, body)
        setSent(true)
      } catch (error: any) {
        setFormError("An unexpected error occurred. Please try again.")
        console.error(error)
      }

      setIsLoading(false)
    } else {
      setErrors(["Please enter a valid email address"])
    }
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen} initialFocusRef={initialRef} motionPreset="slideInBottom" isCentered size="4xl">
      <ModalOverlay
        sx={{
          transition: "none", // This blurs instantly, preventing the delay caused by slideInBottom
        }}
      />
      <ModalContent maxWidth="432px" marginX={4} backgroundColor="grey.cloud">
        <Flex
          direction="column"
          alignSelf="center"
          height="100%"
          paddingTop={{ base: "32px", md: "72px" }}
          paddingX={{ base: "24px", md: "40px" }}
          paddingBottom={{ base: "24px", md: "56px" }}
        >
          <ModalHeader padding="0">
            <Box>
              {!sent ? (
                <>
                  <Text as="h1" size="xl" marginBottom="16px" width={{ base: "75%", md: "100%" }}>
                    {title1}
                  </Text>
                </>
              ) : (
                <Text as="h1" size="xl" marginBottom="16px">
                  {title2}
                </Text>
              )}
            </Box>
          </ModalHeader>

          <ModalCloseButton marginTop="5px" width="12" height="12" />

          <ModalBody paddingTop="0" paddingX="0">
            {!sent ? (
              <>
                {content1.map((text, idx) => (
                  <Text key={`content-${idx}`} mb="4" lineHeight="24px" marginTop={idx === 0 && "0"}>
                    {text}
                  </Text>
                ))}
              </>
            ) : (
              <Flex flexDirection="column" rowGap="2">
                {content2.map((text, idx) => (
                  <Text key={`content-${idx}`} variant={idx > 0 ? "paragraph14" : "paragraph16"} lineHeight="20px">
                    {text}
                  </Text>
                ))}
              </Flex>
            )}

            {colourTag && (
              <Box marginBottom="6">
                {!swatch && (
                  <Text fontWeight="400" fontSize="sm">
                    {product.title}
                  </Text>
                )}
                <Flex alignItems="center" gap={swatch && swatch.colour ? "4" : "2"}>
                  <Text size="sm">{`${colourLabel}:`}</Text>

                  {swatch && swatch.colour && (
                    <Flex display="inline-flex" border="2px solid" borderRadius="full" borderColor="grey.coal" p={0.5}>
                      <Flex borderRadius="full" pos="relative" overflow="hidden" h={8} w={8}>
                        <Box
                          pos="absolute"
                          pointerEvents="none"
                          inset={0}
                          bgColor={swatch?.colour || "grey.fog"}
                          bgImage={swatch?.image?.src ? `url(${swatch.image.src})` : undefined}
                          bgPosition="center"
                          bgSize="cover"
                        />
                      </Flex>
                    </Flex>
                  )}
                  <Text size="sm" textTransform="capitalize">
                    {colourTag}
                  </Text>
                </Flex>
              </Box>
            )}

            <Flex as="form" direction="column" onSubmit={onSubmit}>
              <VStack spacing="6">
                {!sent && (
                  <>
                    {product.variants.length > 1 && (
                      <FormSelect
                        name="sizeVariant"
                        isRequired
                        placeholder="Select Size"
                        value={activeSize}
                        options={product.variants.map(variant => {
                          const variantIdNumber = extractIdFromGid(variant.id)
                          return variantIdNumber
                            ? {
                                value: variantIdNumber,
                                label: variant.title,
                              }
                            : null
                        })}
                        borderRadiusString="8px"
                        onChange={e => setActiveSize(e.target.value)}
                        containerProps={{ width: "100%", backgroundColor: "grey.white" }}
                      />
                    )}
                    <FormInput
                      isRequired
                      name="firstName"
                      onChange={e => {
                        setErrors([])
                        setEmail(e.target.value)
                      }}
                      placeholder={isMedium ? "Your Email here" : "Email"}
                      type="text"
                      value={email}
                      borderColor={errors.length < 1 ? "grey.mid" : "var(--chakra-colors-brand-error) !important"}
                      borderRadius="8px"
                      containerProps={{ width: "100%" }}
                      backgroundColor="grey.white"
                      labelColor={errors.length < 1 ? "grey.mid" : "brand.error"}
                      errors={errors}
                      errorAlignment="flex-start"
                      errorIcon={false}
                    />
                    {subContent1.map((text, idx) => (
                      <Text key={`content-${idx}`} size="xxs">
                        {text}
                      </Text>
                    ))}
                  </>
                )}

                {!sent && (
                  <Flex width="100%">
                    <Button
                      type="submit"
                      size="md"
                      variant="secondaryAlt"
                      isDisabled={loading || email.length < 3 || (product.variants.length > 1 && !activeSize)}
                      isLoading={loading}
                      borderRadius="8px"
                      flex="1"
                    >
                      {button1}
                    </Button>
                  </Flex>
                )}
                <FormErrors errors={formError ? [formError] : []} errorIcon={false} justifyContent="flex-start" />
              </VStack>
            </Flex>
          </ModalBody>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
