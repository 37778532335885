import { ProductCombinedProps, ProductProps } from "@root/types/global"
import { ElasticProduct } from "@usereactify/search"
import { SwatchFull } from "@app/hooks/useSwatch"

// potential to move stockStatuses to sanity config, if so then move values to app config
const stockStatuses: { [key: string]: string } = {
  "coming-soon": "Coming Soon",
  "back-soon": "Notify Me",
  "in-store": "In Store Only",
}

export const useOutOfStock = () => {
  const getStockStatus = (product: ProductCombinedProps | SwatchFull | ProductProps | ElasticProduct, isVariantOut: boolean) => {
    if (!product?.tags) {
      console.error("No tags on product")
      return { stockStatus: null, isNotifyFlow: false, isInStoreOnly: false }
    }
    const stockTagPrefix = product.tags.find(tag => tag.includes("stock:"))
    const stockValue = stockTagPrefix ? stockTagPrefix.split(":")[1] : null

    const isNotifyFlow = stockValue === "coming-soon" || (stockValue === "back-soon" && isVariantOut)
    const isInStoreOnly = stockValue === "in-store"

    let stockStatus = null

    if (stockValue === "coming-soon") {
      stockStatus = stockStatuses[stockValue]
    } else if (stockValue === "back-soon" && isVariantOut) {
      stockStatus = stockStatuses[stockValue]
    } else if (stockValue === "in-store") {
      stockStatus = stockStatuses[stockValue]
    } else {
      stockStatus = null
    }

    return {
      stockValue,
      stockStatus,
      isNotifyFlow,
      isInStoreOnly,
    }
  }

  return {
    getStockStatus,
  }
}
