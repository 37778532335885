import { useCallback, useEffect, useState } from "react"
import { useCore } from "./useCore"

export const useOptimizely = (className: string) => {
  const {
    helpers: { isBrowser },
  } = useCore()
  const [isActiveOptimizlyExperiment, setIsActiveOptimizlyExperiment] = useState(false)

  const handleClassChange = useCallback(
    (newClass: string) => {
      if (newClass.includes(className)) {
        setIsActiveOptimizlyExperiment(true)
      }
    },
    [className]
  )
  const bodyClassObserver = useEffect(() => {
    if (isBrowser) {
      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.attributeName === "class") {
            const bodyClass = document.body.className
            handleClassChange(bodyClass)
          }
        })
      })

      // Start observing the body for attribute changes (like classes)
      observer.observe(document.body, { attributes: true })

      return () => {
        observer.disconnect()
      }
    }
  }, [handleClassChange, isBrowser])

  return { bodyClassObserver, isActiveOptimizlyExperiment }
}
