import gql from "graphql-tag"

import { VARIANT_FRAGMENT } from "./variantFragment"

export const VARIANT_WITH_PRODUCT_FRAGMENT = gql`
  fragment VariantWithProductFragment on ProductVariant {
    ...VariantFragment
    product {
      description
      handle
      id
      productType
      tags
      title
      vendor
    }
  }
  ${VARIANT_FRAGMENT}
`
