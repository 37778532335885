import { useCallback } from "react"

import { useCore } from "@app/hooks/useCore"
import { useImage } from "@app/hooks/useImage"
import { useRoutes } from "@app/hooks/useRoutes"

import type { LinkProps, Location } from "@root/types/global"

export type MenuItem = Menu &
  LinkProps & {
    key: string
    highlight?: boolean
    icon?: string
    image?: any
    subNavImages: Array<any>
    displayCentered: boolean
  }

export type Menu = {
  key: string
  active: boolean
  items?: Array<MenuItem>
  link?: LinkProps
  title: string
  url?: string
}

type UseNavigation = {
  buildItems: (
    items:
      | Array<GatsbyTypes.SanityLinkOrNavigationCollectionOrNavigationPageOrNavigationProductOrNavigationStoreOrNavigationSub>
      | undefined,
    highlights?: GatsbyTypes.SanitySettingMenus["highlights"] | undefined
  ) => MenuItem
}

export const useNavigation = (location?: Location): UseNavigation => {
  const {
    helpers: { handleize, isBrowser },
  } = useCore()
  const { getGatsbyImage } = useImage()
  const { urlResolver } = useRoutes()

  const buildItems = useCallback(
    (items, highlights) =>
      items?.map(link => {
        const type = link._type?.replace("navigation", "")?.replace("Category", "collection").toLowerCase()
        const internal = ["link", "sub", "subcategories"].includes(type || "")
        const icon = link.icon ? handleize(link.icon) : null
        const image = link.image ? getGatsbyImage(link.image, { width: 1000 }) : null

        const subNavImagesArr = link.desktopSubNavImages
        const subNavImages = subNavImagesArr?.map((item: any) => ({ ...item, url: urlResolver(item?.collection) }))
        const item = !internal && type ? link[type] : false
        const items = buildItems(link.items) || false
        const resolvedUrl = urlResolver(item)
        const title = link.title || resolvedUrl?.title
        const url = !internal ? resolvedUrl?.url : link.link || false
        const subLink = urlResolver(link.link) || false
        const highlight = highlights?.find(item => handleize(title).includes(handleize(item))) ? true : false
        const active = (isBrowser && location?.pathname === url) || false
        const displayCentered = link?.displayCentered || false

        return {
          key: link?._key,
          active,
          external: link?.external,
          location,
          highlight,
          icon,
          image,
          title,
          type,
          subNavImages,
          displayCentered,
          ...(subLink ? { link: subLink } : { url }),
          ...(item && { item }),
          ...(items && { items }),
        }
      }) || [],
    [getGatsbyImage, handleize, isBrowser, location, urlResolver]
  )

  return { buildItems }
}
