import React, { memo } from "react"
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby"
import { Link as CustomLink, LinkProps } from "@chakra-ui/react"

interface GatsbyProps extends Omit<GatsbyLinkProps<Record<string, unknown>>, '"ref"'> {
  external?: boolean
  state?: LinkState
}

type LinkState = {
  [key: string]: string
}

type Props = GatsbyProps & LinkProps

// eslint-disable-next-line
const LinkComponent: React.FC<Props> = React.forwardRef(({ children, to = "", external = false, state = {}, ...props }, ref) => {
  const internal = /^\/(?!\/)/.test(to)

  return internal ? (
    <CustomLink as={GatsbyLink} to={to} {...props}>
      {children}
    </CustomLink>
  ) : (
    <CustomLink href={to} {...(external && { isExternal: true, target: "_blank", rel: "noreferrer" })} {...props}>
      {children}
    </CustomLink>
  )
})

export const Link = memo(LinkComponent)
