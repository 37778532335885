import { IMAGE_FRAGMENT } from "../fragments/image"
import { PRODUCT_CARD_FRAGMENT, PRODUCT_VARIANT_FRAGMENT } from "../fragments/products"
import { gql } from "@apollo/client"

export const SIBLING_PRODUCTS_QUERY = (tag: string) => gql`
  query SiblingProducts(
    $countryCode: CountryCode
    $firstImages: Int
    $firstVariants: Int
  ) @inContext(country: $countryCode) {
    products: products(first: 30, query: "tag:'${tag}'") {
      edges {
        node {
          ...ProductCardFragment
        }
      }
    }
  }
  ${PRODUCT_CARD_FRAGMENT}
  ${PRODUCT_VARIANT_FRAGMENT}
  ${IMAGE_FRAGMENT}
`
