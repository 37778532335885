import { useTheme, useMediaQuery } from "@chakra-ui/react"

import { useHydration } from "./useHydration"

export const useMedia = () => {
  const { breakpoints } = useTheme()
  const { isHydrated } = useHydration()

  const queries = {
    base: useMediaQuery(`(max-width: ${breakpoints.sm.replace("rem", "") * 16 - 1}px)`)?.[0],
    sm: useMediaQuery(`(min-width: ${breakpoints.sm.replace("rem", "") * 16}px)`)?.[0],
    md: useMediaQuery(`(min-width: ${breakpoints.md.replace("rem", "") * 16}px)`)?.[0],
    lg: useMediaQuery(`(min-width: ${breakpoints.lg.replace("rem", "") * 16}px)`)?.[0],
    xl: useMediaQuery(`(min-width: ${breakpoints.xl.replace("rem", "") * 16}px)`)?.[0],
    touch: useMediaQuery(`(hover: none)`)?.[0],
  }

  const isBase = isHydrated ? queries.base : true
  const isSmall = isHydrated ? queries.sm : false
  const isMedium = isHydrated ? queries.md : false
  const isLarge = isHydrated ? queries.lg : false
  const isXLarge = isHydrated ? queries.xl : false
  const isTouch = isHydrated ? queries.touch : false

  return { isBase, isSmall, isMedium, isLarge, isXLarge, isTouch }
}
