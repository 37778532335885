import React, { memo, useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Results, useSearch, Stats } from "@usereactify/search"
import { Box, Text, VStack, Flex } from "@chakra-ui/react"

import { useConfigContext } from "@app/providers/config"
import { useMedia } from "@app/hooks/useMedia"

import { Loader } from "@app/components/Loader/Loader"
import { FormButton } from "@app/components/Form/FormButton"
import { SearchFormResultsStats } from "./SearchFormResultsStats"
import { SearchFormResultsListEmpty } from "./SearchFormResultsListEmpty"
import { SearchFormResultsListItem } from "./SearchFormResultsListItem"
import { Link } from "@app/components/Link"

type Props = {
  setValue: (term: string) => void
}

export const SearchFormResults: React.FC<Props> = memo(({ setValue }) => {
  const { search } = useStaticQuery<GatsbyTypes.StaticSearchFormResultsListQuery>(graphql`
    query StaticSearchFormResultsList {
      search: sanityPageSearch {
        additionalAll
        additionalCount
      }
    }
  `)
  const {
    settings: { routes },
  } = useConfigContext()
  const { searchTerm } = useSearch()
  const { isBase, isLarge } = useMedia()

  const gridCols = useMemo(() => (isBase ? 2 : isLarge ? 3 : 2), [isBase, isLarge])
  return (
    <VStack
      alignItems="center"
      justifyContent="space-between"
      spacing={[8, 12]}
      mt={{ md: 0 }}
      className="check-target"
      sx={{ "div:not([class*='css-'])": { width: "100%" } }} // hack to force 'renderError' and 'renderNoResults' to be full width
    >
      <Box>
        <Text letterSpacing="generous" size="xs" fontWeight={600} textAlign="center">
          <Stats render={SearchFormResultsStats} />
        </Text>
        <Text letterSpacing="generous" size="xs" textAlign="center">
          {searchTerm}
        </Text>
      </Box>
      <FormButton
        as={Link}
        textAlign="center"
        title={search?.additionalAll}
        aria-label={search?.additionalAll}
        to={`${routes.SEARCH}?q=${searchTerm}`}
        size="md"
        variant="primary"
        w="50%"
      >
        {search?.additionalAll}
      </FormButton>

      <Results
        listStyle={{
          display: "grid",
          gap: "1.25rem",
          gridTemplateColumns: `repeat(${gridCols}, minmax(0, 1fr))`,
        }}
        pageSize={6}
        renderError={props => (
          <Flex width="100%" className="render-error" position="relative" sx={{ "> div": { width: "100%" } }}>
            <SearchFormResultsListEmpty {...props} error />
          </Flex>
        )}
        renderLoading={() => (
          <VStack alignItems="center" justifyContent="center" h="full">
            <Loader w={34} />
          </VStack>
        )}
        renderPaginationLoadMore={() => <></>}
        renderNoResults={() => (
          <Flex width="100%" className="render-error" position="relative" sx={{ "> div": { width: "100%" } }}>
            <SearchFormResultsListEmpty setValue={setValue} />
          </Flex>
        )}
        renderPaginationNextPrev={() => <></>}
        renderResultCardProduct={props => <SearchFormResultsListItem {...props} />}
      />
    </VStack>
  )
})
