import { useCallback, useMemo } from "react"

import { useConfigContext } from "@app/providers/config"
import { useCore } from "@app/hooks/useCore"

export const useKlaviyo = () => {
  const {
    app: { url },
    settings: { routes },
  } = useConfigContext()
  const {
    helpers: { isDev },
  } = useCore()

  const _learnq = useMemo(() => (typeof window !== `undefined` && window._learnq ? window._learnq : []), [])

  const cleanData = (data: any) => {
    try {
      const clean = JSON.parse(
        JSON.stringify(data, (k, v) =>
          ["id", "internal", "parent", "metadata"].includes(k) || k.startsWith("_")
            ? undefined
            : ["image", "thumbnail"].includes(k)
            ? { alt: v?.alt, url: v?.asset?.url }
            : ["handle"].includes(k)
            ? v?.current
            : ["date", "dateCreated", "dateUpdated", "publishedAt"].includes(k)
            ? `${new Date(v).toISOString()}`
            : ["content", "subcontent", "additionalContent", "description"].includes(k) && Array.isArray(v)
            ? v?.map(({ children }) => children?.[0]?.text)?.join("<br/>")
            : Array.isArray(v) && !v?.length
            ? undefined
            : v?.constructor === Object && Object.entries(v)?.length === 0
            ? undefined
            : typeof v === "boolean"
            ? v
            : v || undefined
        )
      )
      return clean
    } catch (err) {
      console.error("Error parsing klaviyo event data")
    }
    return
  }

  const identify = useCallback(
    customer => {
      if (isDev) console.log(`[KLAVIYO] identify fired.`)
      _learnq.push([
        "identify",
        {
          $email: `${customer?.email}`,
          $first_name: `${customer?.firstName || ""}`,
          $last_name: `${customer?.lastName || ""}`,
          // Custom Properties...
        },
      ])
    },
    [_learnq, isDev]
  )

  const track = useCallback(
    (type, item) => {
      if (isDev) console.log(`[KLAVIYO] track(${type}) fired.`)
      _learnq.push(["track", type, { ...cleanData(item) }])
    },
    [_learnq, isDev]
  )

  const trackProduct = useCallback(
    product => {
      const { title, id, collections, images, vendor, variants, handle } = product
      if (title) {
        const item = {
          Name: title,
          ProductID: id,
          Categories: collections?.map((collection: any) => collection.title),
          ImageURL: images?.[0]?.src,
          URL: `${url}${routes.PRODUCT}/${handle}`,
          Brand: vendor,
          Price: variants?.[0]?.priceV2?.amount,
        }
        track("Viewed Product", item)
        track("trackViewedItem", {
          Title: item.Name,
          ItemId: item.ProductID,
          Categories: item.Categories,
          ImageUrl: item.ImageURL,
          Url: item.URL,
          Metadata: {
            Brand: item.Brand,
            Price: item.Price,
          },
        })
      }
    },
    [routes, track, url]
  )

  return { identify, track, trackProduct }
}
