import React from "react"
import "abortcontroller-polyfill/dist/polyfill-patch-fetch"

import config from "@root/config.js"
import { Layout } from "@app/components/Layout"
import { Providers } from "@app/providers/providers"

export const wrapRootElement = ({ element }) => {
  return <Providers>{element}</Providers>
}

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props} settings={config.settings}>
    {element}
  </Layout>
)
