export const Text = {
  baseStyle: {
    fontSize: "md",
    fontWeight: 400,
    lineHeight: 2,
  },
  sizes: {
    "3xl": {
      fontSize: "3xl",
      lineHeight: 1.14,
      fontWeight: 500,
    },
    xl: {
      fontSize: ["lg", "xl"],
      lineHeight: [1.44, 1.5],
      letterSpacing: "wider",
      textTransform: "capitalize",
    },
    sm: {
      fontSize: "sm",
    },
    xs: {
      fontSize: "xs",
    },
    xxs: {
      fontSize: "xxs",
      lineHeight: 1.8,
    },
    xxxs: {
      fontSize: "xxxs",
      lineHeight: 2.25,
    },
  },
  variants: {
    caps: {
      letterSpacing: "broad",
      textTransform: "uppercase",
    },
    medium: {
      letterSpacing: "ample",
      textTransform: "initial",
    },
  },
}
