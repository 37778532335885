import { useCallback } from "react"

export type Link = null | {
  external?: boolean
  title: string
  url: string
}

export type RouteOptions = {
  retainParameter?: string
  returnUrl?: boolean
}

export const useRoutes = () => {
  const urlResolver = useCallback((source: any, route = ""): Link => {
    if (!source) return null

    const urlParts = []
    const type = source?.document?._type || source?._type
    const item = source?.[type] || source?.document || source
    const external = source?.external || false
    const url = item?.url || source?.link || ""
    const title = source?.title || ""
    const parent = item?.parentCollection?.shopify?.handle || ""
    const handle = item?.meta?.canonicalUrl || item?.handle?.current || item?.shopify?.handle || item?.handle || ""

    if (url?.length) urlParts.push(url)
    if (route?.length && !url?.length) urlParts.push(route)
    if (parent?.length) urlParts.push(`/${parent}`)
    if (handle?.length) urlParts.push(`/${handle}`)

    const completeUrl = type !== "pageHomepage" && url !== "/home" ? urlParts.join("") || "/" : "/"

    return {
      external,
      title,
      url: completeUrl,
    }
  }, [])

  return {
    urlResolver,
  }
}
