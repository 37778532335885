import React from "react"

function SubscribeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="11" fill="none" viewBox="0 0 28 11">
      <path
        fill="#fff"
        d="M13.969 10.128c-5.003 0-9.837-2.173-13.494-6.014-.613-.64-.607-1.749 0-2.389l.638-.673c.544-.57 1.377-.575 1.926-.01C6.005 4.14 9.921 5.889 13.97 5.889c4.047 0 7.963-1.754 10.929-4.847.544-.57 1.383-.565 1.926.01l.639.673c.612.64.617 1.748.005 2.389-3.662 3.84-8.496 6.014-13.5 6.014z"
      ></path>
    </svg>
  )
}

export default SubscribeIcon
