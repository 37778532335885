export const Table = {
  baseStyle: {
    table: {
      fontSize: "xs",
      letterSpacing: "generous",
      w: "full",
    },
    th: {
      fontWeight: 400,
      letterSpacing: "broad",
      textTransform: "uppercase",
      p: 3,
      verticalAlign: "top",
      _first: {
        pl: 0,
      },
      _last: {
        pr: 0,
      },
    },
    tr: {
      borderBottom: "1px solid",
      borderBottomColor: "grey.lake",
      _last: {
        borderBottomColor: "transparent",
      },
    },
    td: {
      px: 3,
      py: 6,
      _first: {
        pl: 0,
      },
      _last: {
        pr: 0,
      },
    },
  },
  variants: {
    rounded: {
      table: {
        letterSpacing: "wider",
      },
      th: {
        bg: "grey.cloud",
        fontWeight: 700,
        letterSpacing: "wider",
        pr: 0,
        py: 4,
        _first: {
          borderLeftRadius: "xl",
          pl: 12.5,
        },
        _last: {
          borderRightRadius: "xl",
          pr: 12.5,
        },
      },
      tr: {
        border: "none",
        borderRadius: "xl",
        overflow: "hidden",
        _even: {
          bg: "grey.cloud",
        },
        _first: {
          fontWeight: 700,
          letterSpacing: "wider",
        },
      },
      td: {
        py: 4,
        _first: {
          borderLeftRadius: "xl",
          fontWeight: 700,
          letterSpacing: "wider",
          pl: 12.5,
        },
        _last: {
          borderRightRadius: "xl",
          pr: 12.5,
        },
      },
    },
  },
  defaultProps: {
    size: "unset",
    variant: "unset",
  },
}
