import React from "react"
import { Text, TextProps } from "@chakra-ui/react"

import { useDiscounts } from "@app/hooks/useDiscounts"
import { useSanityColour } from "@app/hooks/useSanity"

type Props = TextProps & {
  tags: string[]
}

export const Discount: React.FC<Props> = React.memo(({ tags, ...rest }) => {
  const { getDiscountFromTags } = useDiscounts()

  const discount = getDiscountFromTags(tags)
  const { colour } = useSanityColour({ colour: discount }, { colour: discount?.themeColourText?.custom?.hex })

  return discount?.title ? (
    <Text color={colour} {...rest}>
      {discount.title == "Bundle and save" ? "Bundle and Save" : discount.title}
    </Text>
  ) : null
})

export default Discount
