import React, { memo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Text, Box, Container, Divider, GridItem, SimpleGrid } from "@chakra-ui/react"

import { FooterNavigation } from "@app/components/Footer/FooterNavigation"
import { FooterSubscribe } from "@app/components/Footer/Subscribe/FooterSubscribe"
import { FooterUSP } from "@app/components/Footer/FooterUSP"

import { Link } from "@app/components/Link"
import { Icon } from "@app/components/Icon"

import type { Location } from "@root/types/global"

type Props = {
  location: Location
}

export const Footer: React.FC<Props> = memo(({ location }) => {
  const { organisation, footer } = useStaticQuery<GatsbyTypes.StaticFooterQuery>(graphql`
    query StaticFooter {
      organisation: sanitySettingOrganisation {
        title
      }
      footer: sanityTemplateFooter {
        enabled
        acknowledgeContent
      }
    }
  `)

  return (
    <>
      <FooterUSP />

      <Box as="footer" color="grey.navigationText" bgColor="grey.cloud" py={{ base: 8.75, md: 12.5 }}>
        <Container variant="footer">
          <SimpleGrid templateColumns={["1fr", "1fr", "2fr 1fr"]} spacing={[0, 12]}>
            <GridItem order={[2, 2, "unset"]}>
              <FooterNavigation />
            </GridItem>

            <GridItem order={[1, 1, "unset"]}>
              <FooterSubscribe location={location} />
              <Divider borderColor="grey.navigationText" display={["block", "block", "none"]} my={8} />
            </GridItem>
          </SimpleGrid>

          {!footer?.enabled && <Divider borderColor="grey.navigationText" my={[8, 12]} />}

          {footer?.enabled && (
            <>
              <Divider borderColor="grey.navigationText" my={[8, 12]} />

              <SimpleGrid columns={[1, 2]} spacing={12}>
                <GridItem>
                  <Text letterSpacing="generous" size="sm" textAlign="left">
                    {footer?.acknowledgeContent}
                  </Text>
                </GridItem>
                <GridItem>
                  <Link display="flex" title={organisation?.title || ""} aria-label={organisation?.title || ""} to="/" transition="unset">
                    <Icon color="grey.navigationText" maxW={["none", "xl"]} ml="auto" name="brand/logo" title={organisation?.title || ""} />
                  </Link>
                </GridItem>
              </SimpleGrid>
            </>
          )}
        </Container>
      </Box>
    </>
  )
})
