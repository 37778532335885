import { useCallback } from "react"
import { useCartContext } from "@app/providers/cart"
import { useConfigContext } from "@app/providers/config"
import { useCustomerContext } from "@app/providers/customer"
import { useTrackingContext } from "@app/providers/tracking"
import { useCore } from "@app/hooks/useCore"
import { useKlaviyo } from "@app/hooks/useKlaviyo"
import { useShopify } from "@app/hooks/useShopify"
import { useShop } from "./useShop"
import { CustomerProps } from "@root/types/global"

export const stringToSha256 = async (inputString: string) => {
  const encoder = new TextEncoder()
  const data = encoder.encode(inputString)
  const hashBuffer = await crypto.subtle.digest("SHA-256", data)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, "0")).join("")
  return hashHex
}

export const formatAnalyticsPhoneNumber = (phoneNumber: string) => {
  phoneNumber = phoneNumber.replace(/\D/g, "").replace(/^0+/, "")
  return phoneNumber
}

export const formatAnalyticsCity = (city: string) => {
  city = city
    .replace(/\s/g, "")
    .replace(/[^a-zA-Z0-9]/g, "")
    .toLowerCase()
  return city
}

export const useAnalytics = () => {
  const { cart, currencyCode } = useCartContext()
  const {
    store,
    settings: {
      product: { colourPrefix },
    },
  } = useConfigContext()
  const { customer } = useCustomerContext()
  const { tracked, latestVersion, setProductImpressionBatch, pushDataLayer } = useTrackingContext()
  const {
    helpers: { isBrowser, capitalise, decodeShopifyId, formatPrice, edgeNormaliser },
  } = useCore()
  const { track } = useKlaviyo()
  const { productNormaliser } = useShopify()
  const { shop } = useShop()

  const decorateUrl = (url: string): string => {
    if (isBrowser) {
      const ga = window[window["GoogleAnalyticsObject"]]
      if (ga && typeof ga.getAll === "function") {
        const tracker = ga.getAll().length ? ga.getAll()[0] : undefined
        if (tracker) {
          url = new window.gaplugins.Linker(tracker).decorate(url)
        }
      }
      return url
    } else {
      return url
    }
  }

  const getVariantOptionValue = useCallback(
    (tags: Array<string> | null) =>
      capitalise(
        tags
          ?.find(tag => tag?.includes(colourPrefix))
          ?.split(":")?.[1]
          ?.replace(/-/g, " ") || ""
      ),
    [capitalise, colourPrefix]
  )

  const trackPageView = useCallback(() => {
    if (tracked) {
      setTimeout(() => {
        const dataLayer = latestVersion
          ? {
              event: "page_view",
              page_title: document?.title,
              page_path: `${document?.location?.pathname}${document?.location?.search || ""}`,
              path_location: `${document?.location?.protocol}//${document?.location?.hostname}${document?.location?.pathname}${document?.location?.search}`,
              store: store.shopName,
              currency: shop?.currencyCode,
              ...(customer?.id && { customer_id: decodeShopifyId(customer?.id, "Customer") }),
            }
          : {
              event: "Pageview",
              pagePath: `${document?.location?.pathname}${document?.location?.search ? document.location.search : ""}`,
              pageTitle: document?.title,
              originalLocation: `${document?.location?.protocol}//${document?.location?.hostname}${document?.location?.pathname}${document?.location?.search}`,
              store: store.shopName,
              currency: shop?.currencyCode,
            }
        pushDataLayer(dataLayer)
      }, 400)
    }
  }, [decodeShopifyId, tracked, customer, latestVersion, pushDataLayer, shop, store])

  const trackProductImpression = useCallback(
    async (product, index, list = null) => {
      const { collections, id, title, vendor, priceRange, productType } = productNormaliser(product)
      if (title) {
        if (latestVersion) {
          const impression = {
            item_id: decodeShopifyId(id, "Product"),
            item_name: title,
            item_brand: vendor,
            item_variant: getVariantOptionValue(product?.tags),
            item_category: productType ?? collections?.[0]?.title,
            item_list_name: list || "Collection Results",
            item_list_id: "",
            index,
            quantity: 1,
            price: formatPrice(priceRange?.minVariantPrice?.amount || product?.presentmentPriceRanges?.minVariantPrice?.amount),
          }

          setProductImpressionBatch(prevState => (prevState ? [...prevState, impression] : [impression]))
        } else {
          const dataLayer = {
            event: "productImpression",
            ecommerce: {
              currencyCode: currencyCode,
              impressions: [
                {
                  id: id,
                  name: title,
                  brand: vendor,
                  category: collections?.[0]?.title || productType,
                  price: formatPrice(priceRange?.minVariantPrice?.amount || product?.presentmentPriceRanges?.minVariantPrice?.amount),
                  list: list || "Collection",
                  position: index,
                },
              ],
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [
      productNormaliser,
      latestVersion,
      decodeShopifyId,
      getVariantOptionValue,
      formatPrice,
      setProductImpressionBatch,
      currencyCode,
      pushDataLayer,
    ]
  )

  const trackProductClick = useCallback(
    async (product, variant, index, list = null) => {
      const { collections, id, priceRange, presentmentPriceRanges, title, vendor, productType } = product
      if (title) {
        const dataLayer = latestVersion
          ? {
              event: "select_item",
              ecommerce: {
                currency: currencyCode,
                items: [
                  {
                    item_id: decodeShopifyId(id, "Product"),
                    item_name: title,
                    item_brand: vendor,
                    item_variant: getVariantOptionValue(variant?.product?.tags || product?.tags),
                    item_category: productType ?? collections?.[0]?.title,
                    item_list_name: list || "Collection Results",
                    item_list_id: "",
                    index,
                    quantity: 1,
                    price: formatPrice(priceRange?.minVariantPrice?.amount || presentmentPriceRanges?.minVariantPrice?.amount),
                  },
                ],
              },
            }
          : {
              event: "productClick",
              ecommerce: {
                currencyCode: currencyCode,
                click: {
                  actionField: { list: list || "Collection" },
                  products: [
                    {
                      id: id,
                      name: title,
                      brand: vendor,
                      category: collections?.[0]?.title || productType,
                      price: priceRange?.minVariantPrice?.amount,
                      position: index,
                      variant: getVariantOptionValue(variant?.product?.tags || product?.tags),
                    },
                  ],
                },
              },
            }

        pushDataLayer(dataLayer)
      }
    },
    [currencyCode, decodeShopifyId, formatPrice, getVariantOptionValue, latestVersion, pushDataLayer]
  )

  const trackProductView = useCallback(
    async (product, variant, parentCollection) => {
      const { collections, id, productType, title, vendor } = productNormaliser(product)
      if (title) {
        pushDataLayer({ ecommerce: null })
        const dataLayer = latestVersion
          ? {
              event: "view_item",
              ecommerce: {
                currency: currencyCode,
                items: [
                  {
                    item_id: decodeShopifyId(id, "Product"),
                    item_name: title,
                    item_brand: vendor,
                    item_variant: getVariantOptionValue(variant?.product?.tags || product?.tags),
                    item_category: parentCollection || productType || collections?.[0]?.title,
                    quantity: 1,
                    price: formatPrice(variant?.priceV2?.amount || (product?.variants && product?.variants?.[0]?.priceV2)),
                  },
                ],
              },
            }
          : {
              event: "productDetail",
              ecommerce: {
                currencyCode: currencyCode,
                detail: {
                  actionField: { list: "Product Page" },
                  products: [
                    {
                      id: decodeShopifyId(id, "Product"),
                      name: title,
                      brand: vendor,
                      category: parentCollection || collections?.[0]?.title || productType,
                      price: product?.variants && product?.variants[0]?.priceV2?.amount,
                      variant: getVariantOptionValue(variant?.product?.tags || product?.tags),
                    },
                  ],
                },
              },
            }

        pushDataLayer(dataLayer)
      }
    },
    [productNormaliser, pushDataLayer, latestVersion, currencyCode, decodeShopifyId, getVariantOptionValue, formatPrice]
  )

  const trackCartUpdate = useCallback(
    async (type, variantId, quantity, items) => {
      const selectedLineItem = items?.filter(({ merchandise }: { merchandise: any }) => merchandise?.id === variantId)[0]
      if (selectedLineItem?.merchandise.product?.title) {
        const dataLayer = latestVersion
          ? {
              event: ["add", "change"].includes(type) ? "add_to_cart" : "remove_from_cart",
              ecommerce: {
                currency: currencyCode,
                items: [
                  {
                    item_id: decodeShopifyId(variantId, "ProductVariant"),
                    item_name: selectedLineItem?.merchandise.product?.title,
                    item_brand: selectedLineItem?.merchandise?.product?.vendor,
                    item_variant: getVariantOptionValue(selectedLineItem?.merchandise?.product?.tags),
                    item_category: selectedLineItem?.merchandise?.product?.productType,
                    price: formatPrice(selectedLineItem?.merchandise?.priceV2?.amount),
                    quantity,
                    discount:
                      selectedLineItem.discountAllocations.length > 0
                        ? formatPrice(
                            selectedLineItem.discountAllocations
                              ?.reduce((acc, { discountedAmount }) => acc + Number(discountedAmount.amount), 0)
                              ?.toString()
                          )
                        : selectedLineItem?.merchandise?.compareAtPriceV2 !== null
                        ? Number(selectedLineItem.merchandise?.compareAtPriceV2?.amount) -
                          Number(selectedLineItem.merchandise?.priceV2?.amount)
                        : 0,
                  },
                ],
              },
            }
          : {
              event: ["add", "change"].includes(type) ? "addToCart" : "removeFromCart",
              ecommerce: {
                currencyCode: currencyCode,
                [type]: {
                  products: [
                    {
                      id: decodeShopifyId(variantId, "ProductVariant"),
                      name: selectedLineItem?.merchandise.product?.title,
                      brand: selectedLineItem?.merchandise?.product?.vendor,
                      category: selectedLineItem?.merchandise?.product?.productType,
                      price: formatPrice(selectedLineItem?.merchandise?.priceV2?.amount),
                      quantity,
                      variant: getVariantOptionValue(selectedLineItem?.merchandise?.product?.tags),
                      dimension3: selectedLineItem?.merchandise?.availableForSale ? `In Stock` : `Out of Stock`,
                      discount:
                        selectedLineItem.discountAllocations.length > 0
                          ? formatPrice(
                              selectedLineItem.discountAllocations
                                ?.reduce((acc, { discountedAmount }) => acc + Number(discountedAmount.amount), 0)
                                ?.toString()
                            )
                          : selectedLineItem?.merchandise?.compareAtPriceV2 !== null
                          ? Number(selectedLineItem.merchandise?.compareAtPriceV2?.amount) -
                            Number(selectedLineItem.merchandise?.priceV2?.amount)
                          : 0,
                    },
                  ],
                },
              },
            }

        pushDataLayer(dataLayer)

        track(`Cart ${type === "add" ? "Add" : type == "change" ? "Update" : "Remove"}`, {
          ...selectedLineItem,
          quantity,
          product: selectedLineItem.product,
        })
      }
    },
    [latestVersion, currencyCode, decodeShopifyId, getVariantOptionValue, formatPrice, pushDataLayer, track]
  )

  const trackCartView = useCallback(async () => {
    if (cart?.lines?.length) {
      if (latestVersion) {
        const dataLayer = {
          event: "view_cart",
          ecommerce: {
            currency: currencyCode,
            items: cart?.lines?.map((line: any) => ({
              item_id: decodeShopifyId(line?.merchandise?.id, "ProductVariant"),
              item_name: line?.merchandise?.product.title,
              item_brand: line?.merchandise?.product?.vendor,
              item_variant: getVariantOptionValue(line?.merchandise?.product?.tags),
              item_category: line?.merchandise?.product?.productType,
              price: formatPrice(line?.merchandise?.priceV2?.amount),
              quantity: line?.quantity,
              discount:
                line.discountAllocations.length > 0
                  ? formatPrice(
                      line.discountAllocations?.reduce((acc, { discountedAmount }) => acc + Number(discountedAmount.amount), 0)?.toString()
                    )
                  : line?.merchandise?.compareAtPriceV2 !== null
                  ? Number(line.merchandise?.compareAtPriceV2?.amount) - Number(line.merchandise?.priceV2?.amount)
                  : 0,
            })),
          },
        }
        pushDataLayer(dataLayer)
      }
    }
  }, [cart?.lines, currencyCode, decodeShopifyId, formatPrice, getVariantOptionValue, latestVersion, pushDataLayer])

  const trackWishlistUpdate = useCallback(
    async (type, product) => {
      const selectedVariant = product?.variants?.find(variant => variant?.title === product.selectedTitle)
      if (product?.title) {
        if (latestVersion) {
          const dataLayer = {
            event: ["add"].includes(type) ? "add_to_wishlist" : "remove_from_wishlist",
            ecommerce: {
              currency: currencyCode,
              items: [
                {
                  item_id: decodeShopifyId(selectedVariant?.id, "ProductVariant"),
                  item_name: product?.title,
                  item_brand: product?.vendor,
                  item_variant: getVariantOptionValue(product?.tags),
                  item_category: product?.productType,
                  price: formatPrice(
                    selectedVariant?.priceV2?.amount ?? product?.variants?.[0]?.priceV2?.amount ?? product?.variants?.[0]?.price
                  ),
                },
              ],
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [currencyCode, decodeShopifyId, formatPrice, getVariantOptionValue, latestVersion, pushDataLayer]
  )

  const trackPromoImpression = useCallback(
    async ({ analyticsId, name, creative, position }) => {
      if (name) {
        const dataLayer = latestVersion
          ? {
              event: "view_promotion",
              ecommerce: {
                items: [
                  {
                    promotion_id: analyticsId,
                    promotion_name: name,
                    creative_name: creative,
                    creative_slot: position,
                  },
                ],
              },
            }
          : {
              event: "promotionView",
              ecommerce: {
                promoView: {
                  promotions: [{ id: analyticsId, name, creative, position }],
                },
              },
            }
        pushDataLayer(dataLayer)
      }
    },
    [latestVersion, pushDataLayer]
  )

  const trackPromoClick = useCallback(
    async ({ analyticsId, name, creative, position }) => {
      if (name) {
        const dataLayer = latestVersion
          ? {
              event: "select_promotion",
              ecommerce: {
                items: [
                  {
                    promotion_id: analyticsId,
                    promotion_name: name,
                    creative_name: creative,
                    creative_slot: position,
                  },
                ],
              },
            }
          : {
              event: "promotionClick",
              ecommerce: {
                promoClick: {
                  promotions: [{ id: analyticsId, name, creative, position }],
                },
              },
            }
        pushDataLayer(dataLayer)
      }
    },
    [latestVersion, pushDataLayer]
  )

  const trackLogin = useCallback(
    async (method: string) => {
      if (method) {
        if (latestVersion) {
          const dataLayer = {
            event: "login",
            ecommerce: {
              method: capitalise(method),
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, capitalise, pushDataLayer]
  )

  const trackSignup = useCallback(
    async (method: string) => {
      if (method) {
        if (latestVersion) {
          const dataLayer = {
            event: "sign_up",
            ecommerce: {
              method: capitalise(method),
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, capitalise, pushDataLayer]
  )

  const trackShare = useCallback(
    async (method: string, type: string, id: string) => {
      if (method) {
        if (latestVersion) {
          const dataLayer = {
            event: "share",
            ecommerce: {
              method: capitalise(method),
              content_type: type,
              content_id: id,
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, capitalise, pushDataLayer]
  )

  const trackClick = useCallback(
    async (type: string, id: string) => {
      if (type) {
        if (latestVersion) {
          const dataLayer = {
            event: "select_content",
            ecommerce: {
              content_type: type,
              content_id: id,
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, pushDataLayer]
  )

  const trackSearch = useCallback(
    async (term: string) => {
      if (term) {
        if (latestVersion) {
          const dataLayer = {
            event: "search",
            ecommerce: {
              search_term: term,
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, pushDataLayer]
  )

  const trackUserIdentity = useCallback(
    async (customer: CustomerProps) => {
      if (tracked && !!customer && customer?.email) {
        let dataLayer = {}

        const defaultAddress = edgeNormaliser(customer?.addresses)?.find((address: any) => address?.id === customer?.defaultAddress?.id)

        const first_name = customer?.firstName?.toLowerCase()
        const last_name = customer?.lastName?.toLowerCase()
        const city = defaultAddress?.city ? formatAnalyticsCity(defaultAddress.city) : null
        const zip = defaultAddress?.zip
        const phone =
          (customer?.phone && formatAnalyticsPhoneNumber(customer.phone)) ||
          (defaultAddress?.phone && formatAnalyticsPhoneNumber(defaultAddress.phone)) ||
          null
        const country_code = defaultAddress?.countryCode?.toLowerCase()

        if (customer?.email) {
          dataLayer = {
            event: "userIdentify",
            email: customer.email,
            em: await stringToSha256(customer.email),
            ...(first_name && {
              first_name,
              fn: await stringToSha256(first_name),
            }),
            ...(last_name && {
              last_name,
              ln: await stringToSha256(last_name),
            }),
            ...(city && {
              city,
              ct: await stringToSha256(city),
            }),
            ...(zip && {
              zip,
              zp: await stringToSha256(zip),
            }),
            ...(phone && {
              phone,
              ph: await stringToSha256(phone),
            }),
            ...(country_code && {
              country_code,
              country: await stringToSha256(country_code),
            }),
          }
        }
        pushDataLayer(dataLayer)
      }
    },
    [edgeNormaliser, pushDataLayer, tracked]
  )

  return {
    tracked,
    trackPageView,
    trackProductImpression,
    trackProductView,
    trackProductClick,
    trackCartView,
    trackCartUpdate,
    trackWishlistUpdate,
    trackPromoImpression,
    trackPromoClick,
    trackLogin,
    trackSignup,
    trackShare,
    trackSearch,
    trackClick,
    trackUserIdentity,
    decorateUrl,
  }
}
