import { useStaticQuery, graphql } from "gatsby"

export type Props = GatsbyTypes.StaticShopQuery

export const useShop = () =>
  useStaticQuery<GatsbyTypes.StaticShopQuery>(graphql`
    query StaticShop {
      shop: sanitySettingShop {
        name
        email
        weightUnit
        contactEmail
        currencyCode
        myshopifyDomain
        shipsToCountries
        enabledPresentmentCurrencies
        partnerDevelopment
        shopifyPlus
        displayName
        shopPolicies {
          body
          type
          url
        }
        domains {
          host
          url
          localization {
            country
            defaultLocale
          }
        }
        primaryDomain {
          host
          url
          localization {
            country
            defaultLocale
          }
        }
      }
    }
  `)
