import { useCallback, useMemo, useState } from "react"

import { useAnalytics } from "@app/hooks/useAnalytics"
import { useCart } from "@app/hooks/useCart"
import { useCore } from "@app/hooks/useCore"
import { useRoutes } from "@app/hooks/useRoutes"
import { useCartContext } from "@app/providers/cart"
import { useConfigContext } from "@app/providers/config"
import { useWishlistContext, Wishlist, WishlistProduct, WishlistSharedProduct } from "@app/providers/wishlist"

import type { ProductVariantProps } from "@root/types/global"

type UseWishlist = {
  addToWishlist: (data: WishlistProduct) => Promise<void>
  count: number
  deleteFromWishlist: (id: string) => void
  deleteFromWishlistAll: (items: Array<WishlistProduct>) => void
  deleteList: (list: string) => void
  existsInWishlist: (id: string) => boolean
  fetched: boolean
  formatSharedProduct: (product: WishlistProduct) => WishlistSharedProduct
  getSharedWishlist: (id: string, withState?: boolean) => Promise<Array<WishlistProduct>>
  isEmpty: boolean
  items: Array<WishlistProduct>
  lists: Array<string>
  loading: boolean | string
  mergeWishlist: (wishlist: Wishlist) => void
  moveToCart: (variantId: string, quantity?: number) => Promise<void>
  moveToCartAll: (items: Array<WishlistProduct>) => Promise<void>
  moveToWishlist: (data: WishlistProduct, id: string, variantId: string) => Promise<void>
  moveToWishlistAll: (items?: Array<WishlistProduct>) => Promise<void>
  updateList: (item: WishlistProduct, list: string) => void
  updateWishlist: (id: string, variant: ProductVariantProps) => void
  renameList: (name: string, list?: string) => Array<string>
  sharedWishlist: Array<WishlistProduct>
  shareWishlist: (wishlist: Wishlist) => string | null
  shareWishlistUrl: (id: string) => string
  shareUrl: string
}

export const useWishlist = (isShared?: boolean): UseWishlist => {
  const {
    app,
    settings: {
      params,
      product: { colourPrefix, giftCardType },
      routes,
    },
  } = useConfigContext()
  const {
    fetched,
    fetchedShared,
    getWishlist,
    getSharedWishlist,
    mergeWishlist,
    shareUrl,
    shareWishlist,
    shareWishlistUrl,
    sharedWishlist,
    wishlist,
    setWishlist,
  } = useWishlistContext()
  const { cart } = useCartContext()
  const { trackWishlistUpdate } = useAnalytics()
  const { addToCart, addToCartMultiple, clearCart, removeFromCart } = useCart()
  const {
    helpers: { decodeShopifyId },
  } = useCore()
  const { urlResolver } = useRoutes()
  const [loading, setLoading] = useState<boolean | string>(false)

  const filterData = useCallback(
    (allowed, data): WishlistProduct =>
      Object.keys(data)
        .filter(key => allowed.includes(key))
        .reduce(
          (obj, key) => ({
            ...obj,
            [key]: data[key],
          }),
          {}
        ),
    []
  )

  const formatData = useCallback(
    (data: WishlistProduct): WishlistProduct => {
      const allowed = ["id", "selectedSku", "selectedTitle", "handle", "title", "vendor", "image", "images", "tags", "quantity", "list"]

      const list = data?.list || wishlist?.lists?.[0]

      return {
        ...filterData(allowed, data),
        list,
      }
    },
    [filterData, wishlist]
  )

  const formatSharedProduct = useCallback(
    (product: WishlistProduct): WishlistSharedProduct => {
      const selectedVariant = product?.variants?.find(({ title }) => title === product?.selectedTitle) || product?.variants?.[0]
      const colour =
        product?.tags
          ?.find(tag => tag.includes(colourPrefix))
          ?.split(":")?.[1]
          ?.replace(/-/g, " ") || ""
      const image = selectedVariant?.image?.src || product?.images?.[0]?.src || ""
      const price = selectedVariant?.priceV2?.amount || ""
      const title = product?.title || ""
      const variant = selectedVariant?.title || ""
      const variantId = selectedVariant?.id ? decodeShopifyId(selectedVariant.id, "ProductVariant") : null
      const url = `${app?.url}${urlResolver(product, routes.PRODUCT)?.url}${variantId ? `?${params.variant}=${variantId}` : ""}`

      return {
        colour,
        image,
        price,
        title,
        url,
        variant,
      }
    },
    [app?.url, colourPrefix, decodeShopifyId, params.variant, routes.PRODUCT, urlResolver]
  )

  const addToWishlist = useCallback(
    async data => {
      setLoading(true)
      const item = formatData(data)
      const items = wishlist?.items?.length ? [...wishlist.items, item] : [item]
      setWishlist(prevState => ({ ...prevState, items }))
      await getWishlist(items)
      setLoading(false)
      trackWishlistUpdate("add", data)
    },
    [formatData, getWishlist, setLoading, setWishlist, trackWishlistUpdate, wishlist]
  )

  const deleteFromWishlist = useCallback(
    id => setWishlist(prevState => ({ ...prevState, items: prevState.items.filter(item => item?.id !== id) })),
    [setWishlist]
  )

  const deleteFromWishlistAll = useCallback(
    items => setWishlist(prevState => ({ ...prevState, items: prevState.items.filter(item => !items.find(({ id }) => id === item?.id)) })),
    [setWishlist]
  )

  const moveToCart = useCallback(
    async (variantId, quantity) => {
      setLoading(variantId)
      await addToCart(variantId, quantity)

      setLoading(false)
    },
    [addToCart, setLoading]
  )

  const moveToCartAll = useCallback(
    async (items: Array<WishlistProduct>) => {
      setLoading("all")
      const available = items?.filter(
        ({ productType, selectedTitle, variants }) =>
          variants?.find(({ title }) => selectedTitle === title)?.availableForSale && productType !== giftCardType
      )
      const variants = available?.map(item => ({
        quantity: item?.quantity ? Number(item?.quantity) : 1,
        variantId: item?.variants?.find(({ title }) => title === item?.selectedTitle)?.id || "",
      }))

      if (available?.length > 0) await addToCartMultiple(variants)

      setLoading(false)
    },
    [addToCartMultiple, giftCardType, setLoading]
  )

  const moveToWishlist = useCallback(
    async (data, id, variantId) => {
      setLoading(true)
      const item = formatData(data)
      await removeFromCart(id, variantId)
      setWishlist(prevState => ({ ...prevState, items: wishlist?.items?.length ? [...prevState.items, item] : [item] }))
      setLoading(false)
    },
    [formatData, removeFromCart, setLoading, setWishlist, wishlist]
  )

  const moveToWishlistAll = useCallback(
    async items => {
      if (!cart?.lines?.length && !items?.length) return

      const checkoutItems = cart?.lines?.map(({ quantity, merchandise }) => ({
        ...merchandise?.product,
        quantity,
        selectedSku: merchandise?.sku,
        selectedTitle: merchandise?.title,
      }))

      setLoading(true)
      const item = (items?.length ? items : checkoutItems)?.map(data => formatData(data))
      await clearCart()
      setWishlist(prevState => ({ ...prevState, items: wishlist?.items?.length ? [...prevState.items, ...item] : [...item] }))
      setLoading(false)
    },
    [cart?.lines, clearCart, formatData, setLoading, setWishlist, wishlist]
  )

  const existsInWishlist = useCallback(id => wishlist?.items?.filter(item => item?.id === id).length > 0, [wishlist])

  const updateWishlist = useCallback(
    (id, variant) => {
      setLoading(variant?.id)
      setWishlist(prevState => ({
        ...prevState,
        items: [
          ...prevState.items.map(item =>
            item?.id === id
              ? {
                  ...item,
                  selectedTitle: variant?.title,
                }
              : item
          ),
        ],
      }))
      setLoading(false)
    },
    [setLoading, setWishlist]
  )

  const updateList = useCallback(
    (item, list) => {
      if (!item || !list || !wishlist?.items?.length) return

      const items = [...wishlist.items.filter(product => product.id !== item.id), { ...item, list }]

      setWishlist(prevState => ({ ...prevState, items }))

      return []
    },
    [setWishlist, wishlist]
  )

  const renameList = useCallback(
    (name, list) => {
      if (name && list && name === list) return []

      if (!name) return ["Please name your wishlist"]

      const exists = wishlist?.lists?.find(item => item.toLowerCase() === name.toLowerCase())

      if (exists) return ["A wishlist with this name already exists, please rename your wishlist"]

      const lists = list ? [...(wishlist?.lists?.map(item => (item === list ? name : item)) || [])] : [...(wishlist?.lists || []), name]

      const items = [...(wishlist?.items?.map(item => (item?.list === list ? { ...item, list: name } : { ...item })) || [])]

      setWishlist({ items, lists })

      return []
    },
    [setWishlist, wishlist]
  )

  const deleteList = useCallback(
    list => {
      const lists = [...(wishlist?.lists?.filter(item => item !== list) || [])]
      const items = [...(wishlist?.items?.filter(item => item.list !== list) || [])]

      setWishlist({ items, lists })
    },
    [setWishlist, wishlist]
  )

  const items = useMemo(() => (isShared ? sharedWishlist : wishlist?.items), [isShared, sharedWishlist, wishlist])
  const lists = useMemo(
    () => (isShared ? [...new Set([...(sharedWishlist?.map(({ list }) => list) || [])])] : wishlist?.lists),
    [isShared, sharedWishlist, wishlist]
  )
  const count = useMemo(() => wishlist?.items?.length || 0, [wishlist])
  const isEmpty = useMemo(
    () => (!items || items?.length === 0) && (isShared ? fetchedShared : fetched),
    [items, isShared, fetched, fetchedShared]
  )

  return {
    addToWishlist,
    count,
    deleteFromWishlist,
    deleteFromWishlistAll,
    deleteList,
    existsInWishlist,
    fetched,
    formatSharedProduct,
    getSharedWishlist,
    isEmpty,
    items,
    lists,
    loading,
    mergeWishlist,
    moveToCart,
    moveToCartAll,
    moveToWishlist,
    moveToWishlistAll,
    updateList,
    updateWishlist,
    renameList,
    sharedWishlist,
    shareWishlist,
    shareWishlistUrl,
    shareUrl,
  }
}
