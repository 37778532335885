import { useState, useEffect, useCallback, useMemo } from "react"
import { useQuery, useLazyQuery, useMutation, useApolloClient } from "@apollo/client"

import { useCore } from "@app/hooks/useCore"
import { useRoutes } from "@app/hooks/useRoutes"
import { useShop } from "@app/hooks/useShop"
import { useAppContext } from "@app/providers/app"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { useConfigContext } from "@app/providers/config"
import { useCartContext } from "@app/providers/cart"

import type {
  CollectionProps,
  ProductProps,
  ProductVariantProps,
  SelectedOptionProps,
  ImageProps,
  CustomerUserErrorProps,
} from "@root/types/global"
import { SIBLING_PRODUCTS_QUERY } from "@app/graphql/shopify/queries"
import { Cart } from "@shopify/hydrogen-react/storefront-api-types"

interface Discount {
  title: string
  amount: number
  currencyCode: string
}

export const useShopify = () => {
  const client = useApolloClient()
  const { currencyCode, countryCode } = useCartContext()
  const {
    settings: { routes },
  } = useConfigContext()
  const {
    graphql: {
      queries: { GET_COLLECTION_PRODUCT_COMPLETE, GET_PRODUCTS_BY_HANDLE, GET_COLLECTIONS_BY_HANDLE },
    },
    helpers: { decodeShopifyId, encodeShopifyId, edgeNormaliser },
  } = useCore()
  const { urlResolver } = useRoutes()
  const { shop } = useShop()

  const formatErrors = (errors: Array<CustomerUserErrorProps>) => errors?.map(({ message }) => message)

  const formatMoney = (amount: number, currency = "AUD", withPrefix = false) => {
    const country = shop?.primaryDomain?.localization?.country || currency?.slice(0, 2) || "AU"

    const value = new Intl.NumberFormat(`en-${country}`, {
      style: "currency",
      currency,
      currencyDisplay: "narrowSymbol",
    }).format(amount)

    return withPrefix ? `${country} ${value}` : value
  }

  const formatDate = (date: string) =>
    new Intl.DateTimeFormat("en-US", {
      day: "numeric",
      month: "short",
      timeZone: "Australia/Melbourne",
      year: "numeric",
    }).format(new Date(date))

  const imageUrl = (src: string, size: string | number): any => {
    const dimensions = `${size}x${size}`
    const match = typeof src === "string" ? src?.match(/\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif)(\?v=\d+)?$/i) : false

    return match && src?.includes(`shopify.com`) && size && size !== "master"
      ? `${src?.split(match[0])[0]}_${dimensions}${match[0]}`.replace(/http(s)?:/, "")
      : src
  }

  const imageSrcSets = (src: string, size: string | number) =>
    src?.includes(`shopify.com`)
      ? [1, 250, 500, 750, 1000, 1250, 1500, 1750, 2000]
          .filter(set => !size || (size && size >= set))
          .map(set => `${imageUrl(src, set)} ${set}w`)
          .join(`,`)
      : undefined

  const onSale = (price: string, compareAtPrice: string) => compareAtPrice && price && parseInt(compareAtPrice) > parseInt(price)

  const getHandle = (item: any) => item?.handle || item?.shopify?.handle

  const addressNormaliser = ({ id, ...address }, customer = null) => ({
    ...address,
    id,
    default: id === customer?.defaultAddress?.id,
  })

  const orderNormaliser = (orders: any) =>
    orders?.edges?.length > 0
      ? edgeNormaliser(orders)?.map((order: any) => ({
          ...order,
          lineItems: edgeNormaliser(order?.lineItems)?.map(lineItem => ({ ...lineItem, variant: variantNormaliser(lineItem?.variant) })),
        }))
      : []

  const imageNormaliser = (image: any, size: string | number = ""): ImageProps => ({
    alt: image?.altText || image?.alt || image?.asset?.alt || "",
    src: imageUrl(image?.originalSrc || image?.src || image?.url || image?.asset?.url || image || "", size),
    srcSet: image?.srcSet || imageSrcSets(image?.originalSrc || image?.src || image?.url || image?.asset?.url || image || "", size),
  })

  const videoNormaliser = (video: any, size: string | number = "") => ({
    alt: video?.alt || "",
    preview: imageNormaliser(video?.preview?.image || "", size),
    src: [...(video?.sources?.filter(({ format }: { format: string }) => format === "mp4") || [])]?.sort((a: any, b: any) =>
      a.height < b.height ? 1 : -1
    )?.[0],
  })

  const modelNormaliser = (model: any, size: string | number = "") => ({
    alt: model?.alt || "",
    preview: imageNormaliser(model?.preview || "", size),
    sources: model?.sources,
  })

  const priceNormaliser = (presentmentPrices: any, field = "") =>
    Object.assign(
      {},
      ...((Array.isArray(edgeNormaliser(presentmentPrices))
        ? edgeNormaliser(presentmentPrices)
            ?.filter((item: any) => (field && item?.[field] ? item[field] : item)?.currencyCode === (currencyCode || shop?.currencyCode))
            .map((item: any) => priceFieldNormaliser(item, field))
        : []) || {})
    )

  const priceFieldNormaliser = (item: any, field = "") => {
    const price = field && item?.[field] ? item[field] : item

    return {
      amount: `${price?.amount}`,
      local: formatMoney(price?.amount),
      afterpay: formatMoney(price?.amount / 4),
      currencyCode: `${price?.currencyCode || price?.currency_code}`,
    }
  }

  const productNormaliser = (product: any): ProductProps => ({
    ...product,
    collections:
      edgeNormaliser(product?.collections)?.map((collection: any) => ({
        ...collection,
        image: collection?.image ? imageNormaliser(collection?.image) : null,
      })) || [],
    images:
      edgeNormaliser(product?.images)?.length > 0
        ? edgeNormaliser(product?.images)?.map((image: any) => imageNormaliser(image))
        : edgeNormaliser(product?.media)
            ?.filter((media: any) => media?.mediaContentType === "IMAGE")
            ?.map((media: any) => imageNormaliser(media?.image)),
    legacyId: decodeShopifyId(product?.id, "Product"),
    media: product?.media
      ? edgeNormaliser(product?.media)?.map((media: any) => ({
          mediaContentType: media?.mediaContentType,
          ...(media?.mediaContentType === "VIDEO"
            ? videoNormaliser(media)
            : media?.mediaContentType === "IMAGE"
            ? imageNormaliser(media?.image)
            : {}),
        }))
      : [],
    metafields: edgeNormaliser(product?.metafields),
    models: edgeNormaliser(product?.media)
      ?.filter((media: any) => media?.mediaContentType === "MODEL_3D")
      ?.map((media: any) => modelNormaliser(media)),
    presentmentPriceRanges: {
      minVariantPrice: priceNormaliser(product?.presentmentPriceRanges, "minVariantPrice"),
      maxVariantPrice: priceNormaliser(product?.presentmentPriceRanges, "maxVariantPrice"),
    },
    variants: variantsNormaliser(product?.variants) || [],
    videos: edgeNormaliser(product?.media)
      ?.filter((media: any) => media?.mediaContentType === "VIDEO" || media?.mediaContentType === "EXTERNAL_VIDEO")
      ?.map((media: any) => videoNormaliser(media)),
  })

  const variantsNormaliser = (variants: any) => edgeNormaliser(variants)?.map(variantNormaliser)

  const variantNormaliser = (variant: any): ProductVariantProps => ({
    ...variant,
    ...(variant?.image && { image: variant?.image ? imageNormaliser(variant?.image) : null }),
    ...(variant?.metafields && { metafields: edgeNormaliser(variant?.metafields) }),
  })

  const adminProductNormaliser = (product: any, config?: any): ProductProps => ({
    ...product,
    url: routes.PRODUCT,
    availableForSale: product?.variants?.filter(({ available }: { available: boolean }) => available)?.length > 0,
    id: encodeShopifyId(product?.id, "Product"),
    images: product?.images?.map(image => imageNormaliser(image, config?.imageSize || false)) || [],
    legacyId: product?.id,
    productType: product?.product_type,
    presentmentPriceRanges: {
      minVariantPrice: adminPriceNormaliser(product?.presentment_price_ranges?.min_variant_price, "price"),
      maxVariantPrice: adminPriceNormaliser(product?.presentment_price_ranges?.max_variant_price, "price"),
    },
    variants: product?.variants?.map((variant: any) => ({
      ...variant,
      availableForSale: variant?.available,
      id: encodeShopifyId(variant?.id, "ProductVariant"),
      legacyId: variant?.id,
      presentmentPrices: adminPresentmentPriceNormaliser(variant?.presentment_prices),
      adminPresentmentPriceNormaliser: adminPresentmentPriceNormaliser(variant?.presentment_prices),
      priceV2: adminPriceNormaliser(adminPresentmentPriceNormaliser(variant?.presentment_prices), "price"),
      compareAtPriceV2: adminPriceNormaliser(adminPresentmentPriceNormaliser(variant?.presentment_prices), "compareAtPrice"),
      selectedOptions: adminOptionsNormaliser(product, variant),
    })),
  })

  const adminPriceNormaliser = (presentmentPrices: Array<any>, field = "") =>
    Object.assign(
      {},
      ...((presentmentPrices || [])
        ?.filter(
          (item: any) =>
            ((field && item?.[field] ? item[field] : item)?.currency_code ||
              (field && item?.[field] ? item[field] : item)?.currencyCode) === (currencyCode || shop?.currencyCode)
        )
        .map((item: any) => priceFieldNormaliser(item, field)) || {})
    )

  const adminPresentmentPriceNormaliser = (presentment_prices: any) =>
    presentment_prices?.map((presentmentPrice: any) => ({
      compareAtPrice: {
        amount: presentmentPrice?.compare_at_price?.amount,
        currencyCode: presentmentPrice?.compare_at_price?.currency_code,
      },
      price: {
        amount: presentmentPrice?.price?.amount,
        currencyCode: presentmentPrice?.price?.currency_code,
      },
    }))

  const adminOptionsNormaliser = (product: any, variant: any) =>
    product?.options?.map((name: string, index: number) => ({ name, value: variant?.[`option${index + 1}`] }))

  const collectionNormaliser = (collection: GatsbyTypes.SanityCollectionFragmentFragment | CollectionProps) => ({
    ...collection,
    handle: collection?.shopify?.handle || collection?.handle,
    id: collection?.shopify?.id ? parseInt(collection?.shopify?.id) : collection?.id,
    image: collection?.image ? imageNormaliser(collection?.image) : null,
    link: urlResolver(collection),
    ...(collection?.metafields && { metafields: edgeNormaliser(collection?.metafields) }),
    products: collection?.products?.edges?.length ? edgeNormaliser(collection?.products).map(productNormaliser) : [],
  })

  const cartNormaliser = (cart: Cart) => ({
    ...cart,
    lines: edgeNormaliser(cart?.lines) || [],
    deliveryGroups: edgeNormaliser(cart?.deliveryGroups),
  })

  const getCollection = async ({
    firstCollections = 0,
    firstImages = 0,
    firstMedia = 0,
    firstMetafields = 0,
    firstProducts = 0,
    firstVariants = 0,
    handle = "",
  }) => {
    const { data } = await client.query({
      query: GET_COLLECTION_PRODUCT_COMPLETE,
      variables: {
        countryCode,
        firstCollections,
        firstImages,
        firstMedia,
        firstMetafields,
        firstProducts,
        firstVariants,
        metafieldIdentifiers: [],
        handle,
      },
    })

    return collectionNormaliser(data?.collection)
  }

  const getCollections = async ({
    firstCollections = 0,
    firstImages = 0,
    firstMedia = 0,
    firstMetafields = 0,
    firstVariants = 0,
    handles = [],
  }) => {
    const { data } = await client.query({
      query: GET_COLLECTIONS_BY_HANDLE(handles),
      variables: {
        countryCode,
        firstCollections,
        firstImages,
        firstMedia,
        firstMetafields,
        firstVariants,
        metafieldIdentifiers: [],
      },
    })

    // @ts-ignore
    return handles
      ?.filter(handle => data[`collection${handle?.replace(/-/g, "")}`])
      ?.map(handle => collectionNormaliser(data[`collection${handle?.replace(/-/g, "")}`]))
  }

  const getProducts = async ({
    firstCollections = 0,
    firstImages = 0,
    firstMedia = 0,
    firstMetafields = 0,
    firstVariants = 0,
    handles: allHandles = [],
  }): Promise<Array<ProductProps>> => {
    const handles = allHandles?.filter(handle => handle) || []
    const { data } = await client.query({
      query: GET_PRODUCTS_BY_HANDLE(handles),
      variables: {
        countryCode,
        firstCollections,
        firstImages,
        firstMedia,
        firstMetafields,
        firstVariants,
        metafieldIdentifiers: [],
      },
    })

    // @ts-ignore
    return handles
      ?.filter(handle => data[`product${handle?.replace(/-/g, "")}`])
      ?.map(handle => productNormaliser(data[`product${handle?.replace(/-/g, "")}`]))
  }

  const getProductsByTag = async ({
    tag,
    firstImages = 0,
    firstVariants = 0,
  }: {
    tag: string
    firstImages?: number
    firstVariants?: number
  }): Promise<Array<ProductProps>> => {
    const { data } = await client.query({
      query: SIBLING_PRODUCTS_QUERY(tag),
      variables: {
        countryCode,
        firstImages,
        firstVariants,
      },
    })

    // @ts-ignore
    return edgeNormaliser(edgeNormaliser(data)?.products)?.map(product => productNormaliser(product))
  }

  return {
    client,
    useQuery,
    useLazyQuery,
    useMutation,
    formatErrors,
    onSale,
    imageUrl,
    imageSrcSets,
    formatDate,
    formatMoney,
    imageNormaliser,
    cartNormaliser,
    priceNormaliser,
    orderNormaliser,
    addressNormaliser,
    productNormaliser,
    variantNormaliser,
    variantsNormaliser,
    collectionNormaliser,
    adminProductNormaliser,
    getHandle,
    getCollection,
    getCollections,
    getProducts,
    getProductsByTag,
  }
}

export const totalDiscounts = (cart: any): Array<Discount> => {
  const discountMap: { [key: string]: Discount } = {}
  cart?.discountAllocations?.forEach((discount: { title: string; discountedAmount: { amount: string; currencyCode: string } }) => {
    const { title, discountedAmount } = discount
    const key = title.toLowerCase().replace(/\s+/g, "-")
    if (discountMap[key]) {
      discountMap[key].amount += parseFloat(discountedAmount.amount)
    } else {
      discountMap[key] = {
        title,
        amount: parseFloat(discountedAmount.amount),
        currencyCode: discountedAmount.currencyCode,
      }
    }
  })

  return Object.values(discountMap)
}

export const useShopifyProduct = () => {
  const { activeProduct, setActiveProduct, activeVariant, setActiveVariant } = useAppContext()
  const {
    settings: { routes },
  } = useConfigContext()

  const selectProduct = useCallback(
    (product, path) => {
      if (product && path?.includes(routes.PRODUCT)) {
        if (activeProduct?.handle !== product?.shopify?.handle) {
          try {
            setActiveProduct(product?.shopify?.raw ? JSON.parse(product?.shopify?.raw) : product)
          } catch (error) {
            setActiveProduct(product)
          }
        }
      } else {
        if (activeProduct !== null) setActiveProduct(null)
        if (activeVariant !== null) setActiveVariant(null)
      }
    },
    [activeProduct, setActiveProduct, activeVariant, setActiveVariant, routes.PRODUCT]
  )

  return { activeProduct, selectProduct }
}

export const useShopifyFirstAvailable = (product: ProductProps) => {
  const variant = product?.variants?.find(({ availableForSale }) => availableForSale) || product?.variants?.[0]

  return { variant }
}

type UseShopifyVariantsProps = {
  firstAvailable?: boolean
  loading?: boolean
  product: ProductProps
  selectedVariant?: ProductVariantProps
  useParameter?: boolean
}

type UseShopifyVariants = {
  activeVariant: ProductVariantProps | null
  handleOption: (option: SelectedOptionProps) => void
  handleOptions: (options: Array<SelectedOptionProps>) => void
  selectedOptions: Array<SelectedOptionProps>
  variant: ProductVariantProps | null
}

export const useShopifyVariants = ({
  firstAvailable = true,
  loading = false,
  product,
  selectedVariant,
  useParameter = false,
}: UseShopifyVariantsProps): UseShopifyVariants => {
  const {
    helpers: { encodeShopifyId, decodeShopifyId, getUrlParameter, setUrlParameter },
  } = useCore()
  const { activeVariant, activeProduct, setActiveVariant } = useAppContext()
  const { currencyCode } = useCartContext()
  const {
    helpers: { isBrowser },
  } = useCore()
  const { shop } = useShop()
  const {
    settings: { params },
  } = useConfigContext()
  const { trackProductView } = useAnalytics()
  const { id, variants } = product || {}
  const [variant, setVariant] = useState<ProductVariantProps | null>(null)
  const [tracked, setTracked] = useState<string | null>(null)

  const currentVariant = getUrlParameter(params.variant)
  const { variant: firstAvailableVariant } = useShopifyFirstAvailable(product)
  const defaultVariant =
    selectedVariant ||
    (useParameter && variants?.find(({ id }: { id: string }) => id === encodeShopifyId(currentVariant, "ProductVariant"))) ||
    (firstAvailable && firstAvailableVariant)
  const defaultOptions = defaultVariant?.selectedOptions || product?.options?.map(({ name }) => ({ name, value: "" }))

  const [selectedOptions, setSelectedOptions] = useState(defaultOptions)

  const handleOption = useCallback(
    option => setSelectedOptions(selectedOptions?.map(selectedOption => (selectedOption.name === option.name ? option : selectedOption))),
    [selectedOptions, setSelectedOptions]
  )

  const handleOptions = useCallback(options => setSelectedOptions(options), [setSelectedOptions])

  useEffect(() => {
    if (
      (currencyCode || shop?.currencyCode) &&
      !loading &&
      activeVariant &&
      !activeVariant?.priceV2?.amount &&
      defaultVariant?.priceV2?.amount
    ) {
      setSelectedOptions(defaultVariant?.selectedOptions)
    }
    // Intentionally only run at selected times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyCode, id, loading, shop?.currencyCode, variants?.length, defaultVariant?.selectedOptions])

  // intentionally exclude variants from dependencies
  useEffect(() => {
    if (useParameter) {
      const currentVariant =
        variants?.find(
          ({ selectedOptions: variantOptions }: { selectedOptions: any }) =>
            variantOptions?.filter(
              (variantOption: any) =>
                variantOption.value === selectedOptions.find((selectedOption: any) => selectedOption.name === variantOption.name)?.value
            )?.length === selectedOptions?.length
        ) || null

      if (!loading) setActiveVariant(currentVariant)
    } else {
      setVariant(
        variants?.find(
          ({ selectedOptions: variantOptions }: { selectedOptions: any }) =>
            variantOptions?.filter(
              (variantOption: any) =>
                variantOption.value === selectedOptions.find((selectedOption: any) => selectedOption.name === variantOption.name)?.value
            )?.length === selectedOptions?.length
        ) || null
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, selectedOptions])

  useEffect(() => {
    if (useParameter && activeVariant?.id && !loading && isBrowser) {
      if (
        currentVariant !== encodeShopifyId(activeVariant.id, "ProductVariant") &&
        activeVariant.id !== defaultVariant.id &&
        product?.variants?.find(({ id }) => id === activeVariant?.id)
      ) {
        window.history.replaceState(
          null,
          window.document.title,
          setUrlParameter(params.variant, decodeShopifyId(activeVariant.id, "ProductVariant"))
        )
      }
    }
    // Intentionally only run at selected times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVariant?.id, activeVariant?.priceV2?.amount, currencyCode, loading])

  useEffect(() => {
    if (useParameter && currencyCode && activeProduct?.id && !loading && isBrowser && tracked !== activeProduct?.id) {
      trackProductView(activeProduct, activeVariant, false)
      setTracked(activeProduct?.id)
    }
    // Intentionally only run once loading resolved
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProduct?.id, currencyCode, loading])

  return { activeVariant, handleOption, handleOptions, selectedOptions, variant }
}

export const useShopifyPrice = (variant: ProductVariantProps | null, withPrefix = false, quantity = 1, noSale = false) => {
  const { currencyCode } = useCartContext()
  const { formatMoney } = useShopify()

  return useMemo(() => {
    if (!variant) {
      return {
        onSale: false,
        price: undefined,
        currencyCode: "AUD",
        formattedPrice: undefined,
        compareAtPrice: undefined,
        formattedCompareAtPrice: undefined,
      }
    }

    const price = variant?.priceV2?.amount ? Number(variant?.priceV2?.amount) : 0
    const compareAtPrice = variant?.compareAtPriceV2?.amount ? Number(variant?.compareAtPriceV2?.amount) : 0
    const onSale = !!compareAtPrice && !!price && !noSale && compareAtPrice > price / quantity
    const formattedPrice = formatMoney(quantity * price, currencyCode, withPrefix)
    const formattedAfterpayPrice = formatMoney((quantity * price) / 4, currencyCode, withPrefix)
    const formattedCompareAtPrice = formatMoney(quantity * compareAtPrice, currencyCode, withPrefix)
    const formattedQuantityPrice = formatMoney(quantity * price, currencyCode, withPrefix)
    const formattedCompareAuantityPrice = formatMoney(quantity * compareAtPrice, currencyCode, withPrefix)
    const productSetBasePrice = formatMoney(0, currencyCode, withPrefix)

    return {
      price,
      onSale,
      currencyCode,
      compareAtPrice,
      formattedPrice,
      formattedAfterpayPrice,
      formattedCompareAtPrice,
      formattedQuantityPrice,
      formattedCompareAuantityPrice,
      productSetBasePrice,
    }
  }, [variant, noSale, currencyCode, formatMoney, withPrefix, quantity])
}

export const useShopifyPriceRange = (product: any, separator = " to ") => {
  const { currencyCode } = useCartContext()
  const { formatMoney } = useShopify()

  return useMemo(() => {
    if (!product) {
      return {
        priceMin: undefined,
        priceMax: undefined,
        currencyCode: "AUD",
        formattedPriceRange: undefined,
      }
    }

    const priceRangeMin = product?.presentmentPriceRanges?.minVariantPrice || product?.priceRange?.minVariantPrice || 0
    const priceRangeMax = product?.presentmentPriceRanges?.maxVariantPrice || product?.priceRange?.maxVariantPrice || 0
    const priceMin = priceRangeMin?.amount ? Number(priceRangeMin?.amount) : 0
    const priceMax = priceRangeMax?.amount ? Number(priceRangeMax?.amount) : 0
    const formattedPriceRange =
      priceMin < priceMax
        ? `${formatMoney(priceMin, currencyCode)}${separator}${formatMoney(priceMax, currencyCode)}`
        : formatMoney(priceMin, currencyCode)

    return {
      priceMin,
      priceMax,
      currencyCode,
      formattedPriceRange,
    }
  }, [product, currencyCode, separator, formatMoney])
}
