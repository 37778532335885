import { useEffect, useRef } from "react"

export const useOutsideClick = (callback: { (event: React.BaseSyntheticEvent<object, any, any>): void; (): void }) => {
  const ref = useRef()

  // intentionally only run once on ref change
  useEffect(() => {
    const handleClick = (event: { target: any }) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }

    document.addEventListener("click", handleClick, true)

    return () => {
      document.removeEventListener("click", handleClick, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, callback])

  return ref
}
