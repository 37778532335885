import React, { forwardRef } from "react"
import { Box, Button, Flex, Text } from "@chakra-ui/react"

import { useNavigationLinkNormaliser } from "@app/hooks/useNavigationHeader"
import { Icon } from "@app/components/Icon"
import { Link } from "@app/components/Link"

import { IndexObject } from "@app/components/Header/Navigation/HeaderNavigationMobile"
import { NavigationHeaderMobileThirdMenu } from "@app/components/Header/Navigation/HeaderNavigationMobileThirdMenu"

type NavSubmenu = {
  item: GatsbyTypes.SanityObjectMobileLevel2
  indexes: IndexObject
}

export const NavigationHeaderMobileSubmenu = forwardRef<HTMLElement, NavSubmenu>(({ item, indexes }, ref) => {
  const { normaliseLink } = useNavigationLinkNormaliser()
  const { items } = item
  const accordionSubIndex = indexes.subIndex.index

  return (
    <Flex flexDirection="column" position="relative" overflowX="hidden" overflowY="auto" height="100%" marginBottom="auto">
      <Flex
        ref={ref}
        position="absolute"
        top="0"
        left="0"
        overflowY="auto"
        width="100%"
        maxHeight="100%"
        height="100%"
        transform={accordionSubIndex === -1 ? "translateX(0%)" : "translateX(-100%)"}
        transition="transform 0.3s ease"
        flexDirection="column"
        zIndex={1}
        paddingX="6"
      >
        {items?.map((item, index) => {
          const resolvedLink = normaliseLink(item?.link && item?.link.link?.[0] ? item?.link.link?.[0] : null)

          return item && item.items && item.items.length > 0 ? (
            <Button
              key={index}
              onClick={() => {
                indexes.setLevel(2)
                indexes.setSubIndex({ index, title: item?.title || "" })
              }}
              display="flex"
              variant="ghost"
              paddingLeft="3"
              borderBottom={index < items.length - 1 ? "1px solid" : ""}
              borderBottomColor="grey.cloud"
              _hover={{
                color: "unset",
                background: "unset",
                borderBottomColor: "grey.cloud",
              }}
            >
              <Flex
                justifyContent="flex-start"
                alignItems="center"
                height={index < items.length - 1 ? "55px" : "56px"}
                flexWrap="wrap"
                width="100%"
              >
                <Text size="sm" textAlign="left" textTransform="uppercase" lineHeight="20px" color="grey.text">
                  {item?.title}
                </Text>
              </Flex>
              <Flex width="48px" height="48px" justifyContent="center" alignItems="center" marginLeft="auto">
                <Icon name="chevron-nav" width="12" height="12" fill="transparent" />
              </Flex>
            </Button>
          ) : item && resolvedLink ? (
            <Button
              key={index}
              as={Link}
              to={resolvedLink?.url}
              onClick={() => {
                indexes.onClose()
              }}
              display="flex"
              variant="ghost"
              paddingLeft="3"
              borderBottom={index < items.length - 1 ? "1px solid" : ""}
              borderBottomColor="grey.cloud"
              _hover={{
                textDecoration: "none",
              }}
            >
              <Flex
                justifyContent="flex-start"
                alignItems="center"
                height={index < items.length - 1 ? "55px" : "56px"}
                flexWrap="wrap"
                width="100%"
              >
                <Text size="sm" textAlign="left" textTransform="uppercase" lineHeight="20px" color="grey.text">
                  {item?.title}
                </Text>
              </Flex>
            </Button>
          ) : null
        })}
      </Flex>
      {items?.map((item, index) => {
        return item && item.items && item.items.length > 0 ? (
          <Box
            key={index}
            position="absolute"
            top="0"
            left="0"
            overflowY="auto"
            width="100%"
            height="100dvh"
            maxHeight="100%"
            transform={accordionSubIndex === index ? "translateX(0%)" : "translateX(100%)"}
            transition="transform 0.3s ease"
          >
            {items && items?.length > 0 && <NavigationHeaderMobileThirdMenu item={item} indexes={indexes} />}
          </Box>
        ) : null
      })}
    </Flex>
  )
})
