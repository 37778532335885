import React, { memo } from "react"
import { Box } from "@chakra-ui/react"
import { Swiper, SwiperSlide } from "swiper/react"

import { GiftWithPurchaseProduct } from "./GiftWithPurchaseProduct"
import { Icon } from "@app/components/Icon"

import "swiper/css"

type Props = {
  products: any[]
}

export const GiftWithPurchaseSwiper: React.FC<Props> = memo(({ products }) => (
  <Box pos="relative" maxW="75" mx="auto">
    <Box as="button" className="swiper-button-prev" position="absolute" top="calc(50% - 28px)" left="-42px" transform="translateY(-50%)">
      <Icon name="chevron-left" width={4} />
    </Box>

    <Swiper
      cssMode
      pagination={{ clickable: true }}
      slidesPerView={1}
      spaceBetween={15}
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
        disabledClass: "swiper-disabled",
      }}
    >
      {products.map((p: any, i: number) => (
        <SwiperSlide key={`gwp-product-${i}`}>
          <GiftWithPurchaseProduct product={p} />
        </SwiperSlide>
      ))}
    </Swiper>

    <Box as="button" className="swiper-button-next" position="absolute" top="calc(50% - 28px)" right="-42px" transform="translateY(-50%)">
      <Icon name="chevron-right" width={16} />
    </Box>
  </Box>
))
