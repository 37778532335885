import React, { memo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { HStack, Text, StackProps } from "@chakra-ui/react"

import { FormButton } from "./FormButton"

type Props = StackProps & {
  allowZero?: boolean
  handleChange: (quantity: number) => void
  isDisabled?: boolean
  max?: number
  value: number
}

export const FormQuantity: React.FC<Props> = memo(({ allowZero = false, isDisabled = false, handleChange, max, value, ...props }) => {
  const { cart } = useStaticQuery<GatsbyTypes.StaticFormQuantityQuery>(graphql`
    query StaticFormQuantity {
      cart: sanityPageCart {
        additionalIncrease
        additionalDecrease
      }
    }
  `)

  return (
    <HStack alignItems="stretch" justifyContent="space-between" mb={-2} ml={-1} spacing={2} {...props}>
      <FormButton
        isDisabled={isDisabled || value === (allowZero ? 0 : 1)}
        onClick={() => handleChange(value - 1)}
        p={1}
        title={cart?.additionalDecrease}
        aria-label={cart?.additionalDecrease}
        variant="ghost"
      >
        -
      </FormButton>
      <Text alignSelf="center" size="xs" textAlign="center">
        {value}
      </Text>
      <FormButton
        isDisabled={isDisabled || (max !== undefined && value >= max) || false}
        onClick={() => handleChange(value + 1)}
        p={1}
        title={cart?.additionalIncrease}
        aria-label={cart?.additionalIncrease}
        variant="ghost"
      >
        +
      </FormButton>
    </HStack>
  )
})
