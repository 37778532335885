import React, { useCallback } from "react"
import { Center, Heading, Box, Stack, Flex, Text, FormControl, Input, Checkbox } from "@chakra-ui/react"
import { FormButton } from "@app/components/Form/FormButton"
import { useNewsletter } from "@app/hooks/useNewsletter"
import SubscribeThankYou from "./SubscribeThankYou"
import type { Location } from "@root/types/global"
import { Icon } from "@app/components/Icon"

type Props = {
  location: Location
  setOpen: (open: boolean) => void
}

export const SubscribeForm = ({ location, setOpen }: Props) => {
  const { data, errors, handleChange, loading, success, newsletter, checkboxes, setCheckboxes, handleRegistrationSubmit } =
    useNewsletter(location)

  const handleCheckboxToggle = useCallback(
    value => {
      if (value === "all") {
        const currentAllCheckboxValue = checkboxes.find(check => check.value === "all")?.checked
        setCheckboxes(
          checkboxes.map(check => ({
            ...check,
            checked: !currentAllCheckboxValue,
          }))
        )
      } else {
        setCheckboxes(
          checkboxes.map(check => {
            if (check.value === "all") {
              return {
                ...check,
                checked: false,
              }
            }
            return {
              ...check,
              checked: check.value === value ? !check.checked : check.checked,
            }
          })
        )
      }
    },
    [checkboxes, setCheckboxes]
  )

  const customErrors = (errors: Array<string>) => {
    if (!errors.length) return null

    const formattedErrors = errors.map(error => {
      if (error.toLowerCase().includes("email has already been taken")) {
        return "You have already subscribed"
      }
      if (errors.includes("internal")) {
        return "An internal error has occured. Please try again."
      }
      return error
    })
    return formattedErrors
  }

  return (
    <>
      {!success ? (
        <Flex flexDirection="column" alignItems="center" p="64px 36px">
          <Heading as="h2" fontSize="xl" color="#fff" textTransform="uppercase" letterSpacing="7px" lineHeight="32px" textAlign="center">
            {newsletter?.popupTitle}
          </Heading>
          <Text
            textAlign="center"
            as="p"
            mt="2rem"
            color="#fff"
            textTransform="uppercase"
            fontSize={{ base: "sm", sm: "xs" }}
            lineHeight="22px"
            letterSpacing="2.5px"
            maxW={{ sm: "35rem" }}
          >
            {newsletter?.popupSubtitle}
          </Text>

          <Box as="form" mt={{ base: "2rem", md: "3rem" }} w="85%" onSubmit={(e: any) => handleRegistrationSubmit(e, data)}>
            <Stack>
              <FormControl id="popupSubscribeForm">
                <Center>
                  <Box maxW={{ sm: "25rem" }}>
                    <Input
                      type="text"
                      name="firstName"
                      id="firstName"
                      autoComplete="given-name"
                      variant="h3"
                      mb={2}
                      textTransform="uppercase"
                      color="#fff"
                      letterSpacing="0.3em"
                      fontSize="xs"
                      border="none"
                      borderRadius="none"
                      borderBottom="1px solid #fff"
                      p="0"
                      placeholder="First Name"
                      background="none"
                      _placeholder={{ color: "#fff" }}
                      onChange={handleChange}
                      value={data?.firstName}
                      isRequired
                    />
                    <Input
                      type="text"
                      name="lastName"
                      id="lastName"
                      autoComplete="family-name"
                      variant="h3"
                      mt={4}
                      mb={2}
                      textTransform="uppercase"
                      color="#fff"
                      letterSpacing="0.3em"
                      fontSize="xs"
                      border="none"
                      borderRadius="none"
                      borderBottom="1px solid #fff"
                      p="0"
                      placeholder="Last Name"
                      value={data?.lastName}
                      onChange={handleChange}
                      background="none"
                      _placeholder={{ color: "#fff" }}
                      style={{ background: "none" }}
                      _autofill={{ background: "none" }}
                      isRequired
                    />
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      autoComplete="email"
                      value={data?.email}
                      variant="h3"
                      mt={4}
                      mb={2}
                      textTransform="uppercase"
                      color="#fff"
                      letterSpacing="0.3em"
                      fontSize="xs"
                      border="none"
                      borderRadius="none"
                      borderBottom="1px solid #fff"
                      p="0"
                      placeholder="Email"
                      onChange={handleChange}
                      background="none"
                      _placeholder={{ color: "#fff !important" }}
                      style={{ background: "none" }}
                      _autofill={{ background: "none" }}
                      isRequired
                    />
                    {errors?.length > 0 ? (
                      <Flex alignItems="center" color="white" justifyContent="flex-start" mt={2}>
                        <Box flexShrink={0}>
                          <Icon name="error" width={3} />
                        </Box>
                        <Text letterSpacing="generous" size="xs" mb={-0.75} ml={2}>
                          {customErrors(errors)}
                        </Text>
                      </Flex>
                    ) : (
                      ""
                    )}
                  </Box>
                </Center>

                <Text
                  textAlign="center"
                  as="p"
                  mt="2rem"
                  color="#fff"
                  textTransform="uppercase"
                  fontSize={{ base: "10px" }}
                  lineHeight="20px"
                  letterSpacing="0.3em"
                >
                  I&apos;m interested in
                </Text>
                <Flex
                  flexWrap="wrap"
                  justifyContent="center"
                  mt={4}
                  flexDir={{ base: "column", sm: "initial" }}
                  alignItems={{ base: "center", sm: "initial" }}
                >
                  {checkboxes.map(({ title, checked, value }, index) => (
                    <Checkbox
                      key={index}
                      title={title}
                      checked={checked}
                      fontSize="8px"
                      letterSpacing="0.03em"
                      mb={2}
                      ml={2}
                      textTransform="uppercase"
                      bgColor={checked ? "white" : "transparent"}
                      color={checked ? "grey.darker" : "white"}
                      border="white 1px solid"
                      borderRadius="40px"
                      sx={{
                        "& .chakra-checkbox__control": {
                          display: "none",
                        },
                        "& .chakra-checkbox__label": {
                          display: "flex",
                          alignItems: "center",
                          height: "27px",
                          padding: "0 18px",
                          marginLeft: "0",
                          fontSize: "10px",
                        },
                      }}
                      onChange={() => handleCheckboxToggle(value)}
                    >
                      {title}
                    </Checkbox>
                  ))}
                </Flex>
              </FormControl>
              <Flex alignItems="center" flexDirection="column" pt={4}>
                <FormButton
                  variant="primaryInverted"
                  w="218px"
                  lineHeight="20px"
                  paddingTop="3px"
                  height="40px"
                  type="submit"
                  fontSize="sm"
                  textTransform="uppercase"
                  borderRadius="40px"
                  fontWeight={700}
                  isLoading={loading}
                >
                  {newsletter?.popupButtonText}
                </FormButton>
              </Flex>
            </Stack>
          </Box>
        </Flex>
      ) : (
        <SubscribeThankYou setOpen={setOpen} />
      )}
    </>
  )
}
