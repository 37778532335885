import { useCallback, useMemo } from "react"

import { useCore } from "./useCore"
import { useShop } from "./useShop"

export const useStorage = () => {
  const { shop } = useShop()
  const {
    helpers: { handleize, storage: coreStorage },
  } = useCore()

  const storeId = useMemo(() => handleize(shop?.myshopifyDomain?.split(".")?.[0]), [handleize, shop?.myshopifyDomain])

  const get = useCallback((key: string) => coreStorage.get(`${storeId}:${key}`), [storeId, coreStorage])

  const remove = useCallback((key: string) => coreStorage.remove(`${storeId}:${key}`), [storeId, coreStorage])

  const set = useCallback(
    (key: string, value: any, expiryDays?: number) => coreStorage.set(`${storeId}:${key}`, value, expiryDays || undefined),
    [storeId, coreStorage]
  )

  const storage = {
    get,
    remove,
    set,
  }

  return { storage }
}
