import React from "react"
import { Box } from "@chakra-ui/react"

type Props = {
  setOpen: (open: boolean) => void
}

export function SubscribeClose({ setOpen }: Props) {
  return (
    <Box
      position="absolute"
      right={{ base: "20px", md: "40px" }}
      top={{ base: "20px", md: "36px" }}
      zIndex={1000}
      _hover={{ cursor: "pointer" }}
      onClick={() => setOpen(false)}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
        <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M1 1l12 12M1 13L13 1 1 13z"></path>
      </svg>
    </Box>
  )
}
