import React, { useContext, useEffect, useMemo, useState } from "react"
import firebase from "firebase/compat/app"

import { useConfigContext } from "@app/providers/config"
import { useCore } from "@app/hooks/useCore"

type ContextProps = {
  firebase: any
  providers: Providers
  user: firebase.User | null
}

type Providers = {
  facebook: firebase.auth.AuthProvider | null
  google: firebase.auth.AuthProvider | null
}

export const FirebaseContext = React.createContext<ContextProps | undefined>(undefined)

export const FirebaseProvider: React.FC = ({ children }) => {
  const {
    services: { firebase: firebaseConfig },
  } = useConfigContext()
  const {
    helpers: { isBrowser },
  } = useCore()

  const [user, setUser] = useState<firebase.User | null>(null)
  const [providers, setProviders] = useState<Providers>({ google: null, facebook: null })
  const development = process.env.NODE_ENV === "development"

  const contextValue = useMemo<ContextProps>(
    () => ({
      firebase,
      user,
      providers,
    }),
    [user, providers]
  )

  useEffect(() => {
    if (!firebase.apps.length) firebase.initializeApp(firebaseConfig)

    import("firebase/compat/auth")
      .then(() => {
        if (development) firebase.auth().useEmulator("http://localhost:9099/")

        setProviders({
          google: new firebase.auth.GoogleAuthProvider(),
          facebook: new firebase.auth.FacebookAuthProvider(),
        })

        firebase.auth().onAuthStateChanged(user => {
          setUser(user || null)
        })
      })
      .catch(error => console.error("Unable to lazy-load firebase/auth:", error))

    import("firebase/compat/firestore")
      .then(() => {
        // if (development) firebase.firestore().useEmulator("localhost", 8080)
      })
      .catch(error => console.error("Unable to lazy-load firebase/firestore:", error))

    import("firebase/compat/performance")
      .then(() => {
        firebase.performance()
      })
      .catch(error => console.error("Unable to lazy-load firebase/performance:", error))
  }, [firebaseConfig, development])

  return isBrowser ? <FirebaseContext.Provider value={contextValue}>{children}</FirebaseContext.Provider> : <>{children}</>
}

export const useFirebaseContext = (): ContextProps => ({ ...useContext(FirebaseContext) } as ContextProps)
