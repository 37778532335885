export const Input = {
  baseStyle: {
    field: {
      border: "1px solid",
      borderRadius: "2xl",
      fontSize: "xs",
      h: 12.5,
      px: 5,
      _disabled: {
        pointerEvents: "none",
      },
      _focus: {
        boxShadow: "none",
        outline: "none",
      },
      ":autofill": {
        background: "none",
      },
      ":autofill:hover": {
        fbackground: "none",
      },
      ":-webkit-autofill": {
        background: "none",
      },
      ":-webkit-autofill:focus": {
        background: "none",
      },
      ":-webkit-autofill::first-line": {
        background: "none",
      },
      ":-webkit-autofill:hover": {
        background: "none",
      },
      "&:-webkit-autofill:focus": {
        background: "none",
      },
      "&:-webkit-autofill:active": {
        background: "none",
        boxShadow: "0 0 0 60px none inset !important",
        backgroundClip: "content-box !important",
      },
    },
  },
  variants: {
    outline: {
      field: {
        borderColor: "grey.lake",
        boxShadow: "none",
        bg: "transparent",
        color: "grey.darker",
        _hover: {
          borderColor: "grey.lake",
          boxShadow: "none",
        },
        _focus: {
          borderColor: "grey.lake",
          boxShadow: "none",
        },
        _disabled: {
          borderColor: "grey.fog",
          boxShadow: "none",
          bg: "grey.cloud",
          color: "grey.fog",
          opacity: 1,
        },
        _invalid: {
          borderColor: "grey.lake",
          borderWidth: 1,
          boxShadow: "none",
        },
      },
      addon: {
        border: "none",
        bg: "transparent",
      },
    },
    inverted: {
      field: {
        borderColor: "grey.cloud",
        boxShadow: "none",
        bg: "transparent",
        color: "white",
        _hover: {
          borderColor: "white",
          boxShadow: "none",
        },
        _focus: {
          borderColor: "white",
          boxShadow: "none",
        },
        _disabled: {
          borderColor: "grey.fog",
          boxShadow: "none",
          bg: "grey.mid",
          color: "grey.fog",
          opacity: 1,
        },
        _invalid: {
          borderColor: "grey.lake",
          borderWidth: 1,
          boxShadow: "none",
        },
      },
    },
    filled: {
      field: {
        borderColor: "transparent",
        borderRadius: "xxs",
        border: "none",
        bg: "grey.white",
        color: "grey.darkest",
        boxShadow: "none",
        _hover: {
          boxShadow: "none",
          bg: "grey.white",
        },
        _focus: {
          boxShadow: "none",
          bg: "grey.white",
        },
      },
    },
    minimal: {
      field: {
        borderTopColor: "transparent",
        borderRightColor: "transparent",
        borderLeftColor: "transparent",
        borderBottomColor: "grey.mid",
        boxShadow: "none",
        bg: "transparent",
        borderRadius: "none",
        color: "grey.darker",
        h: 10,
        px: 0,
        _placeholder: {
          opacity: 0.5,
        },
        _hover: {
          borderBottomColor: "grey.lake",
          boxShadow: "none",
        },
        _focus: {
          borderBottomColor: "grey.lake",
          boxShadow: "none",
        },
        _disabled: {
          borderBottomColor: "grey.fog",
          boxShadow: "none",
          bg: "grey.cloud",
          color: "grey.fog",
          opacity: 1,
        },
        _invalid: {
          borderWidth: 1,
          boxShadow: "none",
        },
      },
      addon: {
        border: "none",
        bg: "transparent",
      },
    },
    minimalInverted: {
      field: {
        borderTopColor: "transparent",
        borderRightColor: "transparent",
        borderLeftColor: "transparent",
        borderBottomColor: "white",
        boxShadow: "none",
        bg: "transparent",
        borderRadius: "none",
        color: "white",
        h: 10,
        px: 0,
        _hover: {
          borderBottomColor: "white",
          boxShadow: "none",
        },
        _focus: {
          borderBottomColor: "white",
          boxShadow: "none",
        },
        _disabled: {
          borderBottomColor: "grey.fog",
          boxShadow: "none",
          bg: "grey.cloud",
          color: "grey.fog",
          opacity: 1,
        },
        _invalid: {
          borderBottomColor: "white",
          borderWidth: 1,
          boxShadow: "none",
        },
      },
      addon: {
        border: "none",
        bg: "transparent",
      },
    },
  },
  defaultProps: {
    size: "base",
    variant: "outline",
  },
}
