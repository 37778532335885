import React, { memo } from "react"

import { AppProvider } from "@app/providers/app"
import { CartProvider } from "@app/providers/cart"
import { ConfigProvider } from "@app/providers/config"
import { CustomerProvider } from "@app/providers/customer"
import { FirebaseProvider } from "@app/providers/firebase"
import { LocationProvider } from "@app/providers/location"
import { ShopifyProvider } from "@app/providers/shopify"
import { TrackingProvider } from "@app/providers/tracking"
import { ThemeProvider } from "@app/providers/theme"
import { WishlistProvider } from "@app/providers/wishlist"
import { ProductsProvider } from "@app/providers/products"
import { GlobalProvider } from "./global"

export const Providers: React.FC = memo(({ children }) => (
  <LocationProvider>
    <ConfigProvider>
      <GlobalProvider>
        <ShopifyProvider>
          <FirebaseProvider>
            <AppProvider>
              <CustomerProvider>
                <CartProvider>
                  <WishlistProvider>
                    <ProductsProvider>
                      <TrackingProvider>
                        <ThemeProvider>{children}</ThemeProvider>
                      </TrackingProvider>
                    </ProductsProvider>
                  </WishlistProvider>
                </CartProvider>
              </CustomerProvider>
            </AppProvider>
          </FirebaseProvider>
        </ShopifyProvider>
      </GlobalProvider>
    </ConfigProvider>
  </LocationProvider>
))
