import React, { memo, useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { HStack, Text } from "@chakra-ui/react"
import { useSearch } from "@usereactify/search"

import { useAnalytics } from "@app/hooks/useAnalytics"
import { FormButton } from "@app/components/Form/FormButton"

type Props = {
  setValue: (term: string) => void
}

export const SearchFormResultsPopular: React.FC<Props> = memo(({ setValue }) => {
  const { search } = useStaticQuery<GatsbyTypes.StaticSearchFormResultsPopularQuery>(graphql`
    query StaticSearchFormResultsPopular {
      search: sanityPageSearch {
        popular
        additionalPopular
      }
    }
  `)
  const { trackSearch } = useAnalytics()
  const { setSearchTerm } = useSearch()

  const handleSuggestion = useCallback(
    value => {
      setSearchTerm(value)
      setValue(value)
      trackSearch(value)
    },
    [setSearchTerm, setValue, trackSearch]
  )

  return search?.popular?.length > 0 ? (
    <>
      <Text color="grey.mid" size="xs" textAlign="center" variant="caps" mb={4} mt={15}>
        {search?.additionalPopular}
      </Text>
      <HStack alignItems="center" justifyContent="center" spacing={4}>
        {search.popular.map(term => (
          <FormButton
            key={term}
            onClick={() => handleSuggestion(term)}
            color="grey.lake"
            fontSize="xs"
            fontWeight={600}
            letterSpacing="generous"
            textTransform="none"
            variant="ghost"
            _hover={{ color: "grey.mid" }}
          >
            {term}
          </FormButton>
        ))}
      </HStack>
    </>
  ) : null
})
