import { useConfigContext } from "@app/providers/config"
import { useStaticQuery, graphql } from "gatsby"

export const useNavigationHeader = () => {
  const { main } = useStaticQuery<GatsbyTypes.StaticNavigationHeaderQuery>(graphql`
    query StaticNavigationHeader {
      main: sanitySettingMenus {
        primaryHeader {
          items: _rawItems(resolveReferences: { maxDepth: 8 })
          handle {
            current
          }
        }
        primaryMobileHeader {
          items: _rawItems(resolveReferences: { maxDepth: 8 })
          handle {
            current
          }
        }
        secondaryMobileHeader {
          items: _rawItems(resolveReferences: { maxDepth: 8 })
          handle {
            current
          }
        }
      }
    }
  `)

  return {
    header: main?.primaryHeader?.items,
    mobile: {
      primary: main?.primaryMobileHeader?.items,
      secondary: main?.secondaryMobileHeader?.items,
    },
  }
}

export type NavigationLink =
  | GatsbyTypes.SanityLinkOrNavigationCollectionOrNavigationPageOrNavigationProductOrNavigationStore
  | GatsbyTypes.SanityNavigationLinkObject
  | null

export type LinkResult = {
  url: string
  label: string
}

export const useNavigationLinkNormaliser = () => {
  const {
    settings: { routes },
  } = useConfigContext()

  const navigationLinkHandler: { [key: string]: (link: NavigationLink) => LinkResult | undefined } = {
    link: link => ({
      url: link?.link,
      label: link?.title,
    }),
    navigationCollection: link => ({
      url: `${routes.COLLECTION}/${link?.collection?.shopify?.handle}`,
      label: link?.title,
    }),
    navigationPage: link => ({
      url: link?.page?.handle?.current ? `/${link?.page?.handle?.current}` : `${link?.page?.url}`,
      label: link?.title,
    }),
    navigationProduct: link => ({
      url: `${routes.PRODUCT}/${link?.product?.shopify?.handle}`,
      label: link?.title,
    }),
    navigationStore: link => ({
      url: `${routes.STORE}/${link?.store?.handle?.current}`,
      label: link?.title,
    }),
    default: () => null,
  }

  const normaliseLink = (link: NavigationLink) => {
    if (!link || !link._type) return null

    return (navigationLinkHandler[link._type] || navigationLinkHandler.default)(link)
  }

  return {
    normaliseLink,
  }
}
