import { useCallback } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useCore } from "@app/hooks/useCore"

export const useDiscounts = () => {
  const {
    helpers: { edgeNormaliser, handleize },
  } = useCore()

  const { discounts } = useStaticQuery<GatsbyTypes.StaticDiscountsQuery>(graphql`
    query StaticDiscounts {
      discounts: allSanitySettingDiscounts(filter: { enabled: { eq: true } }) {
        edges {
          node {
            title
            tags
            excludedTags
            themeColourText {
              theme
              custom {
                hex
              }
            }
          }
        }
      }
    }
  `)

  const getDiscountFromTags = useCallback(
    (tags: string[]) =>
      (edgeNormaliser(discounts) as GatsbyTypes.SanitySettingDiscounts[]).find(
        discount =>
          // discount is enabled if product has a tag that matches a discount tag and doesn't have a tag that matches an excluded tag
          discount.tags?.find(tag => tag && tags?.find(productTag => handleize(productTag) === handleize(tag))) &&
          !discount.excludedTags?.find(tag => tag && tags?.find(productTag => handleize(productTag) === handleize(tag)))
      ),
    [handleize, edgeNormaliser, discounts]
  )

  return {
    getDiscountFromTags,
  }
}
