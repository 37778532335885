import React, { memo, useState, useEffect, useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Button, FormLabel, Text } from "@chakra-ui/react"

import { FormInput } from "@app/components/Form/FormInput"
import { Icon } from "@app/components/Icon"

import type { Location } from "@root/types/global"
import { useCore } from "@app/hooks/useCore"

type Props = {
  location: Location
  data: {
    email: string
    firstName: string
    lastName: string
  }
  errors: string[]
  handleSubmit: (event: React.BaseSyntheticEvent) => void
  handleChange: (event: React.BaseSyntheticEvent) => void
  loading: boolean
  success: boolean
}

export const FooterSubscribeForm: React.FC<Props> = memo(({ data, errors, handleSubmit, handleChange, loading, success }) => {
  const { newsletter } = useStaticQuery<GatsbyTypes.StaticFooterSubscribeFormQuery>(graphql`
    query StaticFooterSubscribeForm {
      newsletter: sanitySettingNewsletter {
        additionalPlaceholder
        additionalSubmit
        additionalSuccess
        additionalMessage: _rawAdditionalMessage(resolveReferences: { maxDepth: 2 })
      }
    }
  `)

  const [combinedErrors, setCombinedErrors] = useState<string[]>([])

  const handleInvalid = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault()
    const message = event.target.validationMessage
    setCombinedErrors([...errors, message])
  }

  useEffect(() => {
    setCombinedErrors(errors)
  }, [errors])

  const {
    helpers: { sanityContent },
  } = useCore()

  const message = useMemo(() => sanityContent(newsletter?.additionalMessage), [sanityContent, newsletter?.additionalMessage])

  return !success ? (
    <Box as="form" onSubmit={handleSubmit}>
      <FormLabel htmlFor="email" position="static" fontSize="sm" ml="0" color="grey.navigationText">
        Email Address
      </FormLabel>
      <FormInput
        autoComplete="email"
        name="email"
        type="email"
        background="transparent"
        fontSize="md"
        errors={combinedErrors}
        onInvalid={handleInvalid}
        onChange={handleChange}
        isRequired
        value={data?.email}
        errorAlignment="flex-start"
        errorIcon={false}
        variant="filled"
        rightAddon={
          <Button
            iconSpacing="0"
            isLoading={loading}
            rightIcon={<Icon ml="1.125" name="chevron-right" />}
            color="grey.darkest"
            size="sm"
            type="submit"
            title={newsletter?.additionalSubmit || ""}
            aria-label={newsletter?.additionalSubmit || ""}
            variant="primary"
          />
        }
      />

      {message && (
        <Text color="grey.navigationText" mt={1.5} size="xxs">
          {message}
        </Text>
      )}
    </Box>
  ) : (
    <Text color="brand.grey" size="xs">
      {newsletter?.additionalSuccess}
    </Text>
  )
})
