import React, { memo } from "react"
import { GridItem, SimpleGrid, Text, VStack } from "@chakra-ui/react"

import { useNavigationFooter } from "@app/hooks/useNavigationFooter"
import { FormButton } from "@app/components/Form/FormButton"
import { Link } from "@app/components/Link"
import { FooterSocialMedia } from "@app/components/Footer/FooterSocialMedia"

export const FooterNavigation: React.FC = memo(() => {
  const { footer: items } = useNavigationFooter()

  return (
    <SimpleGrid columns={[2, 4]} spacing={12}>
      {items.map(({ key, type, title, url, items }) =>
        type !== "sub" ? (
          <GridItem key={key}>
            <FormButton
              as={Link}
              display="block"
              fontSize="sm"
              color="grey.navigationText"
              lineHeight={2}
              textTransform="none"
              title={title}
              aria-label={title}
              to={url}
              variant="ghost"
            >
              {title}
            </FormButton>
          </GridItem>
        ) : (
          <GridItem key={key}>
            <VStack alignItems="flex-start" justifyContent="flex-start" spacing={1.5}>
              <Text color="grey.navigationText" lineHeight={2.5} size="sm" textTransform="capitalize" fontWeight="bold">
                {title}
              </Text>
              {!!items?.length && (
                <>
                  {items.map(({ key, title, url }) => (
                    <FormButton
                      as={Link}
                      key={key}
                      display="block"
                      fontSize="sm"
                      color="grey.navigationText"
                      lineHeight={2}
                      textTransform="none"
                      title={title}
                      aria-label={title}
                      to={url}
                      variant="ghost"
                      whiteSpace="unset"
                    >
                      {title}
                    </FormButton>
                  ))}
                </>
              )}
            </VStack>
          </GridItem>
        )
      )}

      <GridItem>
        <FooterSocialMedia />
      </GridItem>
    </SimpleGrid>
  )
})
