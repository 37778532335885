export const radii = {
  xxs: "0.25rem",
  xs: "0.875rem",
  sm: "1.3125rem",
  md: "1.5rem",
  lg: "1.5625rem",
  xl: "3.125rem",
  "2xl": "3.75rem",
  "3xl": "18.75rem",
}
