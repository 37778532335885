import { container } from "./breakpoints"
import { space } from "./space"

export const sizes = {
  container,
  ...space,
  "0.5": "50%",
  6.5: "1.625rem",
  7.5: "1.875rem",
  9.5: "2.375rem",
  12.5: "3.125rem",
  15: "3.75rem",
  16.25: "4.0625rem",
  17.5: "4.375rem",
  34: "8.25rem",
  75: "18.75rem",
}
