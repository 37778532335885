import { useState, useEffect } from "react"

import { useCore } from "@app/hooks/useCore"

type Dom = {
  height: number | undefined
  width: number | undefined
}

type Navigator = {
  iOS: boolean | undefined
  name: string | undefined
}

type UseDom = {
  dom: Dom
  navigator: Navigator
}

export const useDom = (): UseDom => {
  const {
    helpers: { isBrowser },
  } = useCore()

  const [dom, setDom] = useState<Dom>({
    height: undefined,
    width: undefined,
  })

  const [nav, setNavigator] = useState<Navigator>({
    iOS: undefined,
    name: undefined,
  })

  useEffect(() => {
    const handleResize = () => {
      setDom({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    const handleNavigator = () => {
      setNavigator({
        iOS: /iPhone|iPad|iPod/i.test(navigator.userAgent) ? true : false,
        name: navigator.userAgent,
      })
    }

    if (isBrowser) {
      window.addEventListener("resize", handleResize)
      handleResize()
      handleNavigator()
    }

    return () => isBrowser && window.removeEventListener("resize", handleResize)
  }, [isBrowser])

  return { dom, navigator: nav }
}
