export const Container = {
  baseStyle: {
    maxW: "container.xl",
    px: { base: 6.25, sm: 7.5, lg: 12.5 },
  },
  variants: {
    footer: {
      px: { base: 6.25, sm: 7.5, lg: 28 },
    },
  },
}
