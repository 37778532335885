import React, { memo, useCallback, useRef } from "react"
import { Box, BoxProps, Divider, Flex, HStack, Select, SelectProps, Text, VStack, useDisclosure, useOutsideClick } from "@chakra-ui/react"
import useScrollbarSize from "react-scrollbar-size"

import { FormErrors } from "./FormErrors"
import { FormQuantity } from "./FormQuantity"
import { Icon } from "@app/components/Icon"

import type { ProductVariantProps } from "@root/types/global"

type Option = {
  content?: string
  disabled?: boolean
  label?: string
  value: string
}

type Props = SelectProps & {
  allowZero?: boolean
  containerProps?: BoxProps
  errors?: Array<string>
  handleQuantity?: (quantity: number) => void
  isDisabled?: boolean
  name: string
  onChange?: (event: React.BaseSyntheticEvent) => void
  options: Array<Option>
  placeholder?: string
  prefix?: string
  productVariant?: ProductVariantProps
  quantity?: number
  centerOptions?: boolean
  hover?: boolean
  placeholderSize?: string
  dropdownHeight?: number
  closeOnInteraction?: boolean
  borderRadiusString?: string
}

export const FormSelect: React.FC<Props> = memo(
  ({
    allowZero,
    containerProps,
    errors = [],
    isDisabled = false,
    handleQuantity,
    name,
    options = [],
    onChange = null,
    placeholder,
    prefix,
    productVariant,
    quantity,
    value,
    variant,
    centerOptions = false,
    hover = false,
    placeholderSize = "xs",
    dropdownHeight = 200,
    closeOnInteraction = true,
    borderRadiusString = "lg",
    ...props
  }) => {
    const { isOpen, onClose, onToggle } = useDisclosure()
    const { width } = useScrollbarSize()
    const variantSelectBox = useRef()
    const handleChange = useCallback(
      ({ target: { value } }) => {
        const syntheticEvent = {
          target: {
            name,
            value,
          },
        }

        isOpen && setTimeout(() => onClose(), 200)
        onChange && onChange(syntheticEvent)
      },
      [name, isOpen, onChange, onClose]
    )

    const handleClose = useCallback(() => {
      if (closeOnInteraction) {
        isOpen && setTimeout(() => onClose(), 200)
      }
    }, [isOpen, onClose, closeOnInteraction])

    useOutsideClick({
      ref: variantSelectBox,
      handler: () => isOpen && handleClose(),
    })

    return (
      <Box {...containerProps}>
        {!variant ? (
          <Flex
            pos="relative"
            alignItems="stretch"
            bg={isOpen ? "white" : "transparent"}
            color={isDisabled ? "grey.fog" : "grey.darker"}
            flexDirection="column"
            rounded={borderRadiusString}
            roundedBottom={isOpen ? "none" : borderRadiusString}
            transition="all 0.2s ease"
            sx={{ ".chakra-select__wrapper": { opacity: 0 }, ...(isDisabled ? { svg: { color: "grey.fog" } } : {}) }}
            ref={variantSelectBox}
          >
            <Select
              icon={<Icon name="chevron-down" />}
              isDisabled={isDisabled}
              isInvalid={errors?.length > 0}
              name={name}
              onChange={onChange}
              pointerEvents="none"
              value={value}
              {...props}
            >
              {placeholder && <option value="">{`${placeholder}${isOpen && !value ? `:` : ``}`}</option>}
              {options?.map(({ label, value }) => (
                <option key={value?.toString()} value={value}>
                  {label || value}
                </option>
              ))}
            </Select>
            <HStack
              pos="absolute"
              alignItems="stretch"
              justifyContent="space-between"
              border="1px solid"
              borderColor={isDisabled ? "grey.fog" : "grey.lake"}
              h="full"
              w="full"
              insetX={0}
              pr={handleQuantity && quantity !== undefined && value !== undefined ? [3.5, 2, 3.5] : 4}
              pl={4}
              rounded={borderRadiusString}
              roundedBottom={isOpen ? "none" : borderRadiusString}
              spacing={[4, 2, 4]}
              top={0}
              transition="all 0.2s ease"
              zIndex={1}
            >
              <HStack
                as="button"
                disabled={isDisabled}
                outline="none"
                onBlur={closeOnInteraction && width === 0 ? handleClose : undefined}
                onClick={onToggle}
                cursor={isDisabled ? "default" : undefined}
                alignItems="center"
                justifyContent={centerOptions ? "center" : "space-between"}
                flex={1}
                type="button"
              >
                {value ? (
                  <Text letterSpacing="generous" size="xs" textAlign="left" color="grey.text">
                    {`${prefix ? `${prefix} ` : ""}${options?.find(option => option.value === value)?.label || value}`}
                  </Text>
                ) : placeholder ? (
                  <Text letterSpacing="generous" size={placeholderSize} textAlign="left" color="grey.text">
                    {`${placeholder}${isOpen ? `:` : ``}`}
                  </Text>
                ) : (
                  <Text />
                )}
                <Box transition="transform 0.2s ease" transform={`rotate(${isOpen ? 180 : 0}deg)`} w={4}>
                  <Icon name="chevron-down" />
                </Box>
              </HStack>
              {handleQuantity && quantity !== undefined && value && (
                <FormQuantity
                  allowZero={allowZero}
                  borderLeft="1px solid"
                  borderLeftColor={isDisabled ? "grey.fog" : "grey.lake"}
                  flexShrink={0}
                  handleChange={handleQuantity}
                  max={productVariant?.quantityAvailable}
                  pl={[3, 2, 3]}
                  spacing={0}
                  value={quantity}
                  w={[20, 14, 20]}
                />
              )}
            </HStack>
            <Box
              border="1px solid"
              borderColor="grey.lake"
              borderTop="none"
              roundedBottom={borderRadiusString}
              pos="absolute"
              insetX={0}
              top="100%"
              bg="white"
              overflowX="hidden"
              opacity={isOpen ? 1 : 0}
              visibility={isOpen ? "visible" : "hidden"}
              transform={`translateY(${isOpen ? "0" : "-10%"})`}
              transition="all 0.2s ease"
              zIndex={5}
            >
              <VStack spacing={0} divider={<Divider my={0} w="full" />} overflowX="hidden" overflowY="auto" maxH={dropdownHeight}>
                {options?.map(({ content, disabled, label, value }) => (
                  <HStack
                    as="button"
                    key={value?.toString()}
                    alignItems="center"
                    bg={disabled ? "grey.cloud" : undefined}
                    color={disabled ? "grey.mid" : undefined}
                    justifyContent={centerOptions ? "center" : "space-between"}
                    onClick={handleChange}
                    py={3.5}
                    px={4}
                    type="button"
                    value={value}
                    w="full"
                    sx={{ "*": { pointerEvents: "none" } }}
                    transition={hover ? "0.3s ease all" : undefined}
                    _hover={hover ? { bg: "grey.cloud" } : undefined}
                  >
                    <Text letterSpacing="generous" size="xs">
                      {label || value}
                    </Text>
                    {content && (
                      <Text letterSpacing="generous" size="xs">
                        {content}
                      </Text>
                    )}
                  </HStack>
                ))}
              </VStack>
            </Box>
          </Flex>
        ) : (
          <Select
            icon={<Icon name="chevron-down" />}
            isDisabled={isDisabled}
            isInvalid={errors?.length > 0}
            name={name}
            onChange={onChange}
            value={value}
            variant={variant}
            {...props}
          >
            {placeholder && <option value="">{placeholder}</option>}
            {options?.map(({ label, value }) => (
              <option key={value?.toString()} value={value}>
                {label || value}
              </option>
            ))}
          </Select>
        )}
        <FormErrors errors={errors} justifyContent="center" mt={2} />
      </Box>
    )
  }
)
