import { createBreakpoints } from "@chakra-ui/theme-tools"

export const container = {
  sm: "48rem", // small tablet 768
  md: "72rem", // tablet 1152
  lg: "90rem", // desktop 1440
  xl: "120rem", // large desktop 1920
}

export const breakpoints = createBreakpoints(container)
