import React from "react"
import { StatsProps } from "@usereactify/search"

export type SearchFormResultsStatsProps = React.ComponentProps<NonNullable<StatsProps["render"]>>

export const SearchFormResultsStats: React.FC<SearchFormResultsStatsProps> = ({ resultStats }) => {
  const resultCount = resultStats.numberOfResults

  return <>{`Search Results ${resultCount && resultCount >= 0 ? `(${resultCount})` : "..."} `}</>
}
