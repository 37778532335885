import React, { memo, useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GridItem, Text, VStack } from "@chakra-ui/react"

import { useCore } from "@app/hooks/useCore"
import { FormButton } from "@app/components/Form/FormButton"
import { Link } from "@app/components/Link"

export const FooterSocialMedia: React.FC = memo(() => {
  const { social } = useStaticQuery<GatsbyTypes.StaticFooterSocialMediaQuery>(graphql`
    query StaticFooterSocialMedia {
      social: sanitySettingSocial {
        channels {
          key: _key
          title
          url
        }
        additionalFooter
      }
    }
  `)
  const {
    helpers: { handleize },
  } = useCore()

  const icons = useMemo(() => ["facebook", "instagram", "youtube", "tiktok"], [])

  const items = useMemo(
    () =>
      social?.channels?.map(item => ({
        ...item,
        handle: handleize(item?.title),
        icon: icons?.includes(handleize(item?.title)),
      })),
    [handleize, icons, social?.channels]
  )

  return (
    <GridItem order={{ base: 2, md: "unset" }}>
      <Text color="grey.navigationText" lineHeight={2.5} mb={2} size="sm" textTransform="capitalize" fontWeight="bold">
        {social?.additionalFooter}
      </Text>
      <VStack alignItems="flex-start" justifyContent="flex-start" spacing={1.5}>
        {items?.map(({ key, handle, title, url }) => (
          <FormButton
            as={Link}
            key={key}
            display="block"
            fontSize="sm"
            color="grey.navigationText"
            isExternal
            lineHeight={2}
            textTransform="none"
            title={title || handle}
            aria-label={title}
            to={url || ""}
            variant="ghost"
            whiteSpace="unset"
          >
            <>{title}</>
          </FormButton>
        ))}
      </VStack>
    </GridItem>
  )
})
