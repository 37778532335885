export const fontSizes = {
  xxxs: "0.5rem", // 8
  xxs: "0.625rem", // 10
  xs: "0.75rem", // 12
  sm: "0.875rem", // 14
  md: "1rem", // 16
  lg: "1.125rem", // 18
  xl: "1.25rem", // 20
  "2xl": "1.5625rem", // 25
  "3xl": "1.75rem", // 28
  "4xl": "1.875rem", // 30
  "5xl": "2rem", // 32
  "6xl": "2.5rem", // 40
  "7xl": "2.8125rem", // 45
  "8xl": "3.5rem", // 56
}

export const letterSpacings = {
  ample: "0.03125rem", // 0.5
  generous: "0.046875rem", // 0.75
  wide: "0.0625rem", // 1
  wider: "0.09375rem", // 1.5
  widest: "0.125rem", // 2
  broad: "0.15625rem", // 2.5
  slack: "0.3125rem", // 5
  slacker: "0.34375rem", // 5.5
  slackest: "0.375rem", // 6
  roomy: "0.40625rem", // 6.5
  loose: "0.4375rem", // 7
  looser: "0.5625rem", // 9
  loosest: "0.75rem", // 12
}
