import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { AspectRatio, Box, Flex, GridItem, Heading, SimpleGrid, Skeleton, SkeletonText } from "@chakra-ui/react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation } from "swiper"
import "swiper/css"

import { useMedia } from "@app/hooks/useMedia"
import { useShopify } from "@app/hooks/useShopify"
import { FormButton } from "@app/components/Form/FormButton"
import { ProductCard } from "@app/components/Product/Card/ProductCard"
import { Icon } from "@app/components/Icon"

import type { ProductProps } from "@root/types/global"
import { useGlobalContext } from "@app/providers/global"

SwiperCore.use([Navigation])

export const SearchFormRelated: React.FC = memo(() => {
  const { additionalNext, additionalPrevious } = useGlobalContext()
  const { search } = useStaticQuery<GatsbyTypes.StaticSearchFormRelatedQuery>(graphql`
    query StaticSearchFormRelated {
      search: sanityPageSearch {
        products {
          shopify {
            handle
          }
        }
        additionalRelated
      }
    }
  `)

  const { isBase } = useMedia()
  const { getHandle, getProducts } = useShopify()
  const [items, setItems] = useState<Array<ProductProps> | null>(null)

  const allItems = useMemo(() => search?.products?.filter(item => getHandle(item)) || [], [getHandle, search?.products])

  const fetchItems = useCallback(async () => {
    const items = await getProducts({ firstImages: 2, firstVariants: 1, handles: allItems.map(item => getHandle(item)) })

    if (items) setItems(items)
  }, [allItems, getHandle, getProducts])

  // intentionally only run once at first render
  useEffect(() => {
    if (!items && allItems.length) fetchItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return allItems.length ? (
    <Flex
      pos="relative"
      flexDirection="column"
      alignItems="stretch"
      justifyContent="center"
      w="full"
      pb={[13.75, 16.25, 14]}
      pt={[13.75, 16.25, 25]}
    >
      <Heading as="p" color="grey.lake" fontSize="sm" mb={[2, 8]} size="h6" textAlign="center">
        {search?.additionalRelated}
      </Heading>
      <Box
        d={[items?.length > 2 ? "block" : "none", items?.length > 3 ? "block" : "none"]}
        pos={["static", "absolute"]}
        inset={0}
        px={[0, 3]}
        mt={[0, 6]}
        mb={[9, 0]}
        mx={["unset", -8]}
      >
        <Flex alignItems="center" justifyContent={["center", "space-between"]} h={["auto", "full"]}>
          <FormButton zIndex={1} id={`search-form-related-left`} p={4} variant="ghost" title={additionalPrevious}>
            <Icon name="chevron-left" width={6} />
          </FormButton>
          <FormButton zIndex={1} id={`search-form-related-right`} p={4} variant="ghost" title={additionalNext}>
            <Icon name="chevron-right" width={6} />
          </FormButton>
        </Flex>
      </Box>
      {items?.length > 0 ? (
        <>
          {(isBase && items.length > 2) || (!isBase && items.length > 3) ? (
            <Box
              px={[0, 10]}
              sx={{
                ".swiper": { w: "full" },
                ".swiper-slide": { overflow: "hidden" },
                ".swiper-button-disabled": { opacity: 0, visibility: "hidden" },
              }}
            >
              <Swiper
                breakpoints={{ 768: { slidesPerView: 3 } }}
                cssMode
                navigation={{ prevEl: `#search-form-related-left`, nextEl: `#search-form-related-right` }}
                slidesPerView={2}
                spaceBetween={20}
              >
                {items?.map((item, index) => (
                  <SwiperSlide key={item?.id}>
                    <ProductCard item={item} pagePosition={index} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          ) : (
            <Flex alignItems="flex-start" justifyContent="center" mx={-2.5} px={[0, 10]}>
              {items.map((item, index) => (
                <ProductCard key={item?.id} item={item} pagePosition={index} px={2.5} w={[1 / 2, 1 / 3]} />
              ))}
            </Flex>
          )}
        </>
      ) : (
        <SimpleGrid columns={[2, 3]} gap={5} px={[0, 10]}>
          {Array.from(Array(isBase ? 2 : 3).keys()).map((_, index) => (
            <GridItem key={index} colSpan={1}>
              <AspectRatio ratio={320 / 450} mb={12}>
                <Skeleton />
              </AspectRatio>
              <SkeletonText w="full" />
            </GridItem>
          ))}
        </SimpleGrid>
      )}
    </Flex>
  ) : null
})
