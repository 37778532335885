import { useCallback, useRef, useState } from "react"
import { navigate } from "gatsby"
import { useSearch } from "@usereactify/search"

import { useAnalytics } from "@app/hooks/useAnalytics"

export const useSearchRedirect = () => {
  const handleRedirect = useCallback((type, url) => ["search", "redirect"].includes(type) && navigate(url), [])

  return { handleRedirect }
}

export const useSearchForm = () => {
  const { trackSearch } = useAnalytics()
  const { searchTerm, submitSearchTerm } = useSearch()
  const [value, setValue] = useState<string>(searchTerm || "")
  const field = useRef<HTMLInputElement>()

  const handleChange = useCallback(({ target: { value } }) => setValue(value), [setValue])

  const handleClear = useCallback(() => {
    setValue("")
    field?.current && field.current.focus()
  }, [field, setValue])

  const handleSubmit = useCallback(
    event => {
      event.preventDefault()

      trackSearch(value)
      submitSearchTerm(value)
    },
    [submitSearchTerm, trackSearch, value]
  )

  return { field, handleChange, handleClear, handleSubmit, setValue, submitSearch: submitSearchTerm, value }
}
