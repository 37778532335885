import { Box, Container, GridItem, SimpleGrid, VStack, Text } from "@chakra-ui/react"
import { graphql, useStaticQuery } from "gatsby"
import React, { memo } from "react"
import { Icon } from "@app/components/Icon"
import { Link } from "@app/components/Link"

export const FooterUSP: React.FC = memo(() => {
  const { footer } = useStaticQuery<GatsbyTypes.StaticFooterQuery>(graphql`
    query StaticFooterUSP {
      footer: sanityTemplateFooter {
        uspLinks {
          _key
          title
          icon {
            icon
          }
          link {
            link
            title
          }
        }
      }
    }
  `)

  return footer?.uspLinks?.length ? (
    <Box as="section" bgColor="grey.white" color="grey.navigationText" py={8} borderTop="1px solid" borderColor="grey.cloud">
      <Container maxW="container.md">
        <SimpleGrid columns={3} spacing={4} alignItems="flex-start">
          {footer?.uspLinks?.map(item => (
            <GridItem key={item?._key}>
              {item?.link?.link ? (
                <Link to={item.link.link} title={item.link.title || item.title || ""}>
                  <VStack spacing={0} px={[0, 8]}>
                    {item.icon?.icon && <Icon name={item.icon.icon} title={item.title || ""} w={6} />}

                    {item.title && (
                      <Text size="sm" fontSize={["xs", "sm"]} fontWeight={500} textAlign="center">
                        {item.title}
                      </Text>
                    )}
                  </VStack>
                </Link>
              ) : (
                <VStack spacing={0} px={[0, 8]}>
                  {item?.icon?.icon && <Icon name={item.icon.icon} title={item.title || ""} w={6} />}

                  {item?.title && (
                    <Text size="sm" fontSize={["xs", "sm"]} fontWeight={500} textAlign="center">
                      {item.title}
                    </Text>
                  )}
                </VStack>
              )}
            </GridItem>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  ) : null
})
