import { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useNavigation, MenuItem } from "@app/hooks/useNavigation"

import type { Location } from "@root/types/global"

type UseNavigationFooter = {
  account: Array<MenuItem>
  collections: Array<MenuItem>
  footer: Array<MenuItem>
  header: Array<MenuItem>
}

export const useNavigationFooter = (location?: Location): UseNavigationFooter => {
  const { buildItems } = useNavigation(location)

  const { main } = useStaticQuery<GatsbyTypes.StaticNavigationFooterQuery>(graphql`
    query StaticNavigationFooter {
      main: sanitySettingMenus {
        footer {
          items: _rawItems(resolveReferences: { maxDepth: 4 })
          handle {
            current
          }
        }
      }
    }
  `)

  const footer = useMemo(() => buildItems(main?.footer?.items), [main, buildItems])

  return { footer }
}
