export const Progress = {
  baseStyle: {
    filledTrack: {
      bg: "grey.lake",
    },
    track: {
      bg: "transparent",
      border: "1px solid",
      borderColor: "grey.lake",
      borderRadius: "sm",
      h: "0.3125rem",
    },
  },
  defaultProps: {
    size: "unset",
    colorScheme: "unset",
  },
}
