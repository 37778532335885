import { extendTheme } from "@chakra-ui/react"

import { styles } from "./styles"
import * as components from "./components"
import * as foundations from "./foundations"

const overrides = {
  styles,
  ...foundations,
  components: {
    ...components,
  },
}

export default extendTheme(overrides)
