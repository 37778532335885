import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { navigate } from "gatsby"

import { useConfigContext } from "@app/providers/config"
import { useCustomerContext } from "@app/providers/customer"
import { useFirebaseContext } from "@app/providers/firebase"
import { useCore } from "@app/hooks/useCore"
import { useShopify } from "@app/hooks/useShopify"
import { useStorage } from "@app/hooks/useStorage"

import type { ProductProps, ProductVariantProps } from "@root/types/global"
import { useCartContext } from "@app/providers/cart"

export type WishlistProduct = ProductProps & {
  list: string
  quantity: number
  selectedSku: string
  selectedTitle: string
}

export type WishlistSharedProduct = {
  colour: string
  image: string
  price: string
  title: string
  url: string
  variant: ProductVariantProps
}

export type Wishlist = {
  items: Array<WishlistProduct>
  lists: Array<string>
}

export type ContextProps = {
  fetched: boolean
  fetchedShared: boolean
  getWishlist: (items: Array<WishlistProduct>) => Promise<void>
  getSharedWishlist: (id: string, withState?: boolean) => Promise<Array<WishlistProduct>>
  mergeWishlist: (wishlist: Wishlist) => void
  resetWishlist: () => void
  shareUrl: string
  shareWishlistUrl: (wishlist: string) => string
  shareWishlist: (wishlist: Wishlist) => string | null
  sharedWishlist: Array<WishlistProduct>
  setSharedWishlist: React.Dispatch<React.SetStateAction<Array<WishlistProduct>>>
  wishlist: Wishlist
  setWishlist: React.Dispatch<React.SetStateAction<Wishlist>>
}

export const WishlistContext = React.createContext<ContextProps | undefined>(undefined)

export const WishlistProvider: React.FC = ({ children }) => {
  const {
    helpers: { decodeBase64, encodeBase64 },
    graphql: {
      queries: { GET_PRODUCTS_BY_HANDLE },
    },
  } = useCore()
  const { customer } = useCustomerContext()
  const { countryCode } = useCartContext()
  const { firebase } = useFirebaseContext()
  const { client, productNormaliser } = useShopify()
  const { storage } = useStorage()
  const {
    app,
    services: {
      firebase: { defaultWishlist, wishlistCollection },
    },
    settings: { keys, routes },
  } = useConfigContext()

  const INITIAL_STATE = useMemo(() => ({ items: [], lists: [defaultWishlist] }), [defaultWishlist])

  const [wishlist, setWishlist] = useState<Wishlist>(INITIAL_STATE)
  const [sharedWishlist, setSharedWishlist] = useState<Array<WishlistProduct>>([])
  const [shareUrl, setShareUrl] = useState<string>("")
  const [merged, setMerged] = useState<boolean>(false)
  const [fetched, setFetched] = useState<boolean>(false)
  const [fetchedShared, setFetchedShared] = useState<boolean>(false)

  const getWishlist = useCallback(
    async items => {
      const { data } = await client.query({
        query: GET_PRODUCTS_BY_HANDLE(items?.map((product: ProductProps) => product?.handle)),
        variables: {
          countryCode,
          firstCollections: 0,
          firstImages: 3,
          firstMedia: 0,
          firstMetafields: 0,
          firstVariants: 100,
          metafieldIdentifiers: [],
        },
      })

      setWishlist(prevState => ({
        ...prevState,
        items: items?.map((item: WishlistProduct) => ({
          ...item,
          ...productNormaliser(data[`product${item?.handle?.replace(/-/g, "")}`]),
        })),
      }))
    },
    [client, GET_PRODUCTS_BY_HANDLE, setWishlist, productNormaliser, countryCode]
  )

  const getSharedWishlist = useCallback(
    async (id, withState = true) => {
      if (id) {
        try {
          const products = decodeBase64(id)
            .split("|")
            .map(item => ({
              handle: item.split(":")[0],
              list: item.split(":")[3],
              quantity: item.split(":")[2] || 1,
              selectedTitle: item.split(":")[1],
            }))

          const { data } = await client.query({
            query: GET_PRODUCTS_BY_HANDLE(products?.map((product: ProductProps) => product?.handle)),
            variables: {
              countryCode,
              firstCollections: 0,
              firstImages: 3,
              firstMedia: 0,
              firstMetafields: 0,
              firstVariants: 100,
              metafieldIdentifiers: [],
            },
          })

          const items = products?.map((item: WishlistProduct) => ({
            ...item,
            ...productNormaliser(data[`product${item?.handle?.replace(/-/g, "")}`]),
          }))

          if (withState) setSharedWishlist(items)

          return items
        } catch (error) {
          console.error(error)
        }
      }
      setFetchedShared(true)
    },
    [decodeBase64, client, GET_PRODUCTS_BY_HANDLE, countryCode, setFetchedShared, setSharedWishlist, productNormaliser]
  )

  const shareWishlistUrl = useCallback(id => `${app?.url}${routes.SAVED}/${id}`, [app?.url, routes.SAVED])

  const shareWishlist = useCallback(
    (wishlist: Wishlist) => {
      const items = wishlist?.items?.filter(({ handle }) => handle)

      if (!items?.length) return null

      const string = encodeBase64(items?.map(item => `${item.handle}:${item.selectedTitle}:${item?.quantity || 1}:${item?.list}`).join("|"))
      const url = shareWishlistUrl(string)

      setShareUrl(url)

      return url
    },
    [encodeBase64, setShareUrl, shareWishlistUrl]
  )

  const mergeWishlist = useCallback(
    (wishlist: Wishlist, redirect = true) => {
      if (!wishlist) return

      setWishlist(prevState => {
        const items = [
          ...(prevState?.items || {}),
          ...(wishlist.items?.filter(({ handle }) => !prevState?.items?.find(item => item?.handle === handle)) || {}),
        ]
        const lists = [
          ...new Set([
            ...(prevState?.lists || {}),
            ...(wishlist.lists?.length > 0 ? wishlist.lists : [...(wishlist.items?.map(({ list }) => list) || [])]),
          ]),
        ]

        return { items, lists }
      })

      redirect && navigate(routes.WISHLIST)
    },
    [routes.WISHLIST, setWishlist]
  )

  const mergeLocalWishlist = useCallback(() => {
    if (merged) return

    setMerged(true)

    const localWishlistEncoded = storage.get(keys.wishlist)

    if (localWishlistEncoded?.items && localWishlistEncoded?.lists?.length > 0) {
      try {
        const localWishlistItems = decodeBase64(localWishlistEncoded.items)
          .split("|")
          .map(item => ({
            handle: item.split(":")[0],
            list: item.split(":")[3],
            quantity: item.split(":")[2] || 1,
            selectedTitle: item.split(":")[1],
          }))
        const localWishlist = {
          ...localWishlistEncoded,
          items: localWishlistItems,
        }

        if (localWishlist?.items?.length > 0 || localWishlist?.lists?.length > 1) {
          mergeWishlist(localWishlist, false)
          storage.remove(keys.wishlist)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }, [decodeBase64, keys.wishlist, mergeWishlist, merged, setMerged, storage])

  const saveWishlist = useCallback(
    async wishlist => {
      if (!fetched || !firebase.firestore) return

      if (!customer?.id) {
        storage.set(keys.wishlist, wishlist)

        return
      }

      const wishlistDoc = firebase.firestore().collection(wishlistCollection).doc(btoa(customer?.id))

      if (wishlistDoc) {
        await wishlistDoc
          .set(
            {
              ...wishlist,
            },
            { merge: true }
          )
          .then(() => null)
          .catch(error => {
            console.error(error)
          })

        mergeLocalWishlist()
      }
    },
    [customer, mergeLocalWishlist, fetched, firebase, keys.wishlist, storage, wishlistCollection]
  )

  const getSavedWishlist = useCallback(async () => {
    if (!firebase.firestore) return

    if (!customer?.id) {
      const data = storage.get(keys.wishlist)

      if (data?.items?.length > 0 || data?.lists?.length > 1) {
        const items = data?.items?.length > 0 ? await getSharedWishlist(data?.items, false) : []
        const lists = data?.lists || [defaultWishlist]

        setWishlist({ items, lists })

        if (data?.items?.length > 0) getWishlist(items)
      }

      setFetched(true)

      return
    }

    const wishlistDoc = firebase.firestore().collection(wishlistCollection).doc(btoa(customer?.id))

    if (wishlistDoc) {
      wishlistDoc.onSnapshot(async doc => {
        if (doc.exists) {
          const data = doc.data()

          if (data?.items?.length > 0 || data?.lists?.length > 1) {
            const items = data?.items?.length > 0 ? await getSharedWishlist(data?.items, false) : []
            const lists = data?.lists || [defaultWishlist]

            setWishlist({ items, lists })

            if (data?.items?.length > 0) getWishlist(items)
          }

          setFetched(true)
        } else {
          setFetched(true)
          mergeLocalWishlist()
        }
      })
    }
  }, [
    customer,
    defaultWishlist,
    firebase,
    getWishlist,
    getSharedWishlist,
    keys.wishlist,
    mergeLocalWishlist,
    storage,
    setFetched,
    setWishlist,
    wishlistCollection,
  ])

  const resetWishlist = useCallback(() => {
    setFetched(false)
    setWishlist(INITIAL_STATE)
    setMerged(false)
  }, [INITIAL_STATE, setFetched, setMerged, setWishlist])

  // update wishlist, only run once when items change
  useEffect(() => {
    const url = wishlist ? shareWishlist(wishlist) : null
    const items = url?.split(`${routes.SAVED}/`)?.[1] || ""

    if (!fetched) return
    ;(wishlist?.items?.length > 0 && items) || wishlist?.lists?.length > 1
      ? saveWishlist({ items, lists: wishlist.lists })
      : saveWishlist(INITIAL_STATE)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wishlist])

  // initialize wishlist, only run once when first load
  useEffect(() => {
    getSavedWishlist()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.id, firebase?.firestore])

  const contextValue = useMemo<ContextProps>(
    () => ({
      fetched,
      fetchedShared,
      getWishlist,
      getSharedWishlist,
      mergeWishlist,
      resetWishlist,
      shareUrl,
      shareWishlist,
      shareWishlistUrl,
      sharedWishlist,
      setSharedWishlist,
      wishlist,
      setWishlist,
    }),
    [
      fetched,
      fetchedShared,
      getWishlist,
      getSharedWishlist,
      mergeWishlist,
      resetWishlist,
      shareUrl,
      shareWishlist,
      shareWishlistUrl,
      sharedWishlist,
      setSharedWishlist,
      wishlist,
      setWishlist,
    ]
  )

  return <WishlistContext.Provider value={contextValue}>{children}</WishlistContext.Provider>
}

export const useWishlistContext = (): ContextProps => ({ ...useContext(WishlistContext) } as ContextProps)
