export const Modal = {
  baseStyle: {
    dialog: {
      borderRadius: "none",
      bg: "white",
      my: 0,
      boxShadow: "none",
    },
    body: {
      px: 0,
      py: 0,
    },
    header: {
      px: 0,
      py: 0,
    },
    overlay: {
      bg: "grey.overlay",
      backdropFilter: "blur(11px)",
      transition: "all 0.2s ease",
    },
  },
  sizes: {
    sm: {
      dialog: {
        maxW: 500,
      },
      body: {
        pt: 7.5,
        px: 7.5,
        pb: 17.5,
      },
    },
    md: {
      dialog: {
        maxW: 892,
      },
      body: {
        pt: 7.5,
        px: 7.5,
        pb: 17.5,
      },
    },
    xl: {
      dialog: {
        maxH: "none",
        maxW: "none",
        h: "100vh",
      },
    },
  },
  defaultProps: {
    size: "unset",
  },
}
