import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useQuery } from "@apollo/client"

import { useCore } from "@app/hooks/useCore"
import { useStorage } from "@app/hooks/useStorage"
import { useConfigContext } from "@app/providers/config"

import type { CustomerProps, CustomerAccessTokenProps } from "@root/types/global"
import { useCartContext } from "./cart"

type ContextProps = {
  customer: CustomerProps | null
  getCustomer: (token: CustomerAccessTokenProps) => Promise<CustomerProps | void>
  setCustomer: (customer: CustomerProps) => void
  clearCustomer: () => void
  saveCustomer: (customerAccessToken: CustomerAccessTokenProps) => void
}

export const CustomerContext = React.createContext<ContextProps | undefined>(undefined)

export const CustomerProvider: React.FC = ({ children }) => {
  const {
    graphql: {
      queries: { GET_CUSTOMER },
    },
  } = useCore()
  const { storage } = useStorage()
  const { countryCode } = useCartContext()
  const {
    settings: { keys },
  } = useConfigContext()
  const [customer, setCustomer] = useState<CustomerProps | null>(null)
  const { refetch: getCustomerQuery } = useQuery(GET_CUSTOMER, {
    fetchPolicy: "no-cache",
    skip: true,
  })

  const token = useMemo(() => storage.get(keys.customer), [storage, keys])

  useEffect(() => {
    getCustomer(token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCustomer = useCallback(
    async (token: CustomerAccessTokenProps) => {
      const _countryCode = countryCode ?? storage.get(keys.market) ?? "AU"
      if (token?.accessToken) {
        try {
          const {
            data: { customer },
          } = await getCustomerQuery({
            countryCode: _countryCode,
            customerAccessToken: token?.accessToken,
          })

          if (customer) {
            setCustomer(customer)
            return customer
          } else {
            storage.remove(keys.customer)
          }
        } catch (error) {
          console.error(error)
        }
      }
    },
    [getCustomerQuery, setCustomer, keys, storage, countryCode]
  )

  const saveCustomer = useCallback(
    (customerAccessToken: CustomerAccessTokenProps) => {
      try {
        const { accessToken, expiresAt } = customerAccessToken
        storage.set(keys.customer, { accessToken, expiresAt })
        getCustomer(customerAccessToken)
      } catch (error) {
        console.error(error)
      }
    },
    [getCustomer, keys, storage]
  )

  const clearCustomer = useCallback(() => {
    try {
      storage.remove(keys.wishlistTip)
      storage.remove(keys.customer)
      setCustomer(null)
    } catch (error) {
      console.error(error)
    }
  }, [setCustomer, keys, storage])

  const contextValue = useMemo<ContextProps>(
    () => ({
      customer,
      getCustomer,
      setCustomer,
      saveCustomer,
      clearCustomer,
    }),
    [customer, getCustomer, saveCustomer, clearCustomer]
  )

  return <CustomerContext.Provider value={contextValue}>{children}</CustomerContext.Provider>
}

export const useCustomerContext = (): ContextProps => ({ ...useContext(CustomerContext) } as ContextProps)
