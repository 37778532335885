export const Checkbox = {
  baseStyle: {
    container: {},
    control: {
      borderRadius: "xxs",
      bg: "grey.cloud",
      border: "1px solid",
      borderColor: "grey.darker",
      transition: "all 0.2s ease",
      h: 4,
      w: 4,
      _checked: {
        bg: "grey.cloud",
        borderColor: "grey.darker",
        _hover: {
          bg: "grey.cloud",
          borderColor: "grey.coal",
        },
      },
      _disabled: {
        bg: "grey.cloud",
        borderColor: "grey.fog",
        _checked: {
          bg: "grey.cloud",
          borderColor: "grey.fog",
        },
      },
      _hover: {
        bg: "grey.cloud",
        borderColor: "grey.coal",
      },
      _focus: {
        boxShadow: "none",
        outline: "none",
      },
    },
    icon: {
      color: "grey.darker",
      h: 2,
    },
    label: {
      fontSize: "xs",
      letterSpacing: "generous",
      ml: 3,
      mb: -0.75,
    },
  },
  defaultProps: {
    size: "unset",
  },
}
