import { useCallback, useState } from "react"

export const useFunctions = (initialData = {}) => {
  const [data, setData] = useState(initialData)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Array<string>>([])

  const handleChange = ({ target: { type, name, value, checked } }: React.ChangeEvent<HTMLInputElement>) =>
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))

  const callFunction = useCallback(async (name: string, data = {}) => {
    try {
      setLoading(true)
      setErrors([])

      const response = await fetch(`${process.env.GATSBY_PATH_PREFIX ? `/${process.env.GATSBY_PATH_PREFIX}` : ""}/api/${name}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })

      const body = await response.json()

      setLoading(false)

      if (!response.ok) {
        const errorMessage = body?.body ? body.body : "An error occurred while processing the request."
        setErrors(prevErrors => [...prevErrors, errorMessage])
        return { status: "error", body: errorMessage }
      }

      return { ...body }
    } catch (err) {
      setLoading(false)
      const errorMessage = (err as Error).message
      setErrors([errorMessage])
      return { status: "error", body: errorMessage }
    }
  }, [])

  const clearErrors = useCallback(() => setErrors([]), [setErrors])

  return { callFunction, loading, errors, data, handleChange, clearErrors }
}
