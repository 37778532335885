export const Tabs = {
  baseStyle: {
    tab: {
      color: "grey.darker",
      d: "inline-block",
      fontSize: "xs",
      letterSpacing: "wider",
      lineHeight: 2.25,
      mx: 3.75,
      textTransform: "uppercase",
      borderBottom: "1px solid",
      borderBottomColor: "transparent",
      _first: {
        ml: 0,
      },
      _last: {
        mr: 0,
      },
      _hover: {
        boxShadow: "none",
        borderBottomColor: "transparent !important",
        // _after: {
        //   w: "full",
        // },
      },
      _selected: {
        borderBottomColor: "brand.sunshine",
        // _after: {
        //   w: "full",
        // },
      },
      _active: {
        bg: "transparent",
      },
      _focus: {
        boxShadow: "none",
      },
    },
    tabpanel: {
      fontSize: "xs",
      pt: [8.5, 12.5],
      px: 0,
      pb: 0,
    },
  },
  variants: {
    productContent: {
      tab: {
        fontSize: "sm",
        lineHeight: 1.75,
        fontWeight: 400,
        letterSpacing: "generous",
        textTransform: "none",
        borderBottom: "none",
        color: "grey.text",
        _selected: {
          borderBottom: "1px solid",
          borderBottomColor: "grey.text",
          fontWeight: 600,
        },
        _hover: {
          borderBottom: "1px solid",
          borderBottomColor: "grey.text",
        },
      },
      tabpanel: {
        fontSize: "sm",
        color: "grey.text",
        letterSpacing: "generous",
        pt: 2,
        px: 0,
        pb: 0,
      },
    },
  },
  defaultProps: {
    size: "unset",
    variant: "unset",
  },
}
