import React, { lazy, memo, useCallback, useEffect, useMemo, useState } from "react"
import { Text, VStack, StackProps, Box } from "@chakra-ui/react"

import { useCore } from "@app/hooks/useCore"
import { useGlobalContext } from "@app/providers/global"

type Props = StackProps & {
  color?: string
  minimal?: boolean
  size?: number
  simplified?: boolean
}

export const Loader: React.FC<Props> = memo(({ simplified = false, color, minimal, size, ...rest }) => {
  const { messages: _messages, additionalLoading } = useGlobalContext()
  const {
    helpers: { ErrorBoundary, isBrowser },
  } = useCore()
  const [message, setMessage] = useState(0)

  const shuffle = useCallback((array: Array<any>) => array.sort(() => Math.random() - 0.5), [])

  const messages = useMemo(() => shuffle((_messages as string[] | null) || []), [_messages, shuffle])

  const LoaderIcon = lazy(() => import("./LoaderIcon"))

  // Intentionally only run at first render
  useEffect(() => {
    setInterval(() => setMessage(prevState => (prevState === messages?.length - 1 ? 0 : prevState + 1)), 1550)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <VStack alignItems="center" justifyContent="center" color={color || "grey.mid"} textAlign="center" spacing={0} w="full" {...rest}>
      {simplified ? (
        <>
          <Box className="loader-ring" mb="10px">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </Box>
        </>
      ) : (
        <>
          {isBrowser ? (
            <ErrorBoundary>
              <LoaderIcon color={color} size={size} />
            </ErrorBoundary>
          ) : null}
        </>
      )}
      {((!minimal && messages?.length > 0) || minimal) && (
        <Text size="xs" variant="caps" w="full">
          {minimal ? additionalLoading : messages[message]}
        </Text>
      )}
    </VStack>
  )
})
