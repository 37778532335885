export const Accordion = {
  baseStyle: {
    container: {
      borderColor: "grey.fog",
    },
    button: {
      color: "grey.darker",
      fontSize: "xs",
      fontWeight: 600,
      letterSpacing: "generous",
      textAlign: "left",
      px: 0,
      py: 4.5,
      _hover: {
        bg: "none",
        color: "grey.darker",
      },
      _focus: {
        boxShadow: "none",
        outline: "none",
      },
    },
    panel: {
      fontSize: "xs",
      pl: 0,
      pr: 8,
      pt: 0,
      pb: 4.5,
    },
  },
  sizes: {
    lg: {
      container: {
        _first: {
          borderTopWidth: 0,
        },
      },
      button: {
        fontSize: "2xl",
        fontWeight: 900,
        letterSpacing: "slackest",
        textTransform: "uppercase",
        py: 6.25,
      },
      panel: {
        pb: 12.5,
      },
    },
  },
}
