import React, { useEffect, useState } from "react"
import { Button, ButtonProps } from "@chakra-ui/react"

import { FormErrors } from "./FormErrors"
import { Icon } from "@app/components/Icon"
import { Link } from "@app/components/Link"

import type { DisplayableErrorProps } from "@root/types/global"
import type { LinkProps } from "@root/types/global"

export type Props = ButtonProps & {
  errors?: Array<DisplayableErrorProps | string>
  noRotate?: boolean
  variant?: string
  link?: LinkProps
  isActiveLink?: boolean
  fontWeight?: number
}

export const FormButton: React.FC<Props> = ({
  children,
  errors,
  noRotate,
  variant,
  link,
  isActiveLink = true,
  fontWeight,
  ...props
}): JSX.Element => {
  const [pointerEventsType, setPointerEventsType] = useState("none")

  useEffect(() => {
    setTimeout(() => {
      setPointerEventsType(isActiveLink ? "auto" : "none")
    }, 500)
  }, [isActiveLink])

  return (
    <>
      <Button
        as={link ? Link : "button"}
        to={link ? link.url : ""}
        spinner={variant === "primary" ? <Icon name="brand/favicon" w={8} /> : undefined}
        variant={variant}
        fontWeight={fontWeight}
        {...props}
        {...(!noRotate ? { className: variant } : {})}
        pointerEvents={pointerEventsType}
      >
        {variant === "primary" && <Icon name="brand/favicon" color="grey.white" w={8} display="block !important" className="add-icon" />}
        <span>{children}</span>
      </Button>
      <FormErrors errors={errors} justifyContent="center" mt={2} />
    </>
  )
}
